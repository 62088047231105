import GAListener from "./components/GAListener";
import { EmptyLayout, LayoutRoute, MainLayout } from "./components/Layout";
import Login from "./pages/Login";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./styles/reduction.scss";
import "./styles/style.scss";

import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Users from "./pages/Users";
import Posts from "./pages/Posts";
import Rewards from "./pages/Rewards";
import Notifications from "./pages/Notifications";
import Admins from "./pages/Admins";
import RolesAndPermissions from "./pages/RolesAndPermissions";
import DeletedUsers from "./pages/DeletedUsers";
import Takeaways from "./pages/Takeaways";
import DeletedPosts from "./pages/DeletedPosts";
import WorkoutClasses from "./pages/WorkoutClasses";
import Lounge from "./pages/Lounge";
import MpkUsers from "./pages/MpkUsers";
import ContactRequests from "./pages/ContactRequests";
import Services from "./pages/Services";
import ServiceRequests from "./pages/ServiceRequests";
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={(props) => <Login {...props} />}
            />

            <MainLayout>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/mpkUsers" component={MpkUsers} />
              <Route exact path="/mpkUsers/contactRequests/:id" component={ContactRequests} />
              <Route exact path="/mpkUsers/services/:id" component={Services} />
              <Route exact path="/mpkUsers/services/:id/serviceRequests/:serviceId" component={ServiceRequests} />
              <Route exact path="/videos" component={Posts} />
              <Route exact path="/rewards" component={Rewards} />
              <Route exact path="/notifications" component={Notifications} />
              <Route exact path="/admins" component={Admins} />
              <Route exact path="/takeaways" component={Takeaways} />
              <Route exact path="/lounges" component={Lounge} />

              <Route
                exact
                path="/rolesAndPermissions"
                component={RolesAndPermissions}
              />
              <Route exact path="/deletedUsers" component={DeletedUsers} />
              <Route exact path="/deletedPosts" component={DeletedPosts} />
              <Route exact path="/liveWorkouts" component={WorkoutClasses} />
            </MainLayout>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
