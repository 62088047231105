import React from "react";
import Moment from "react-moment";
import {
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
  Table,
  Badge,
} from "reactstrap";
import Page from "../components/Page";
import Typography from "../components/Typography";
import PageSpinner from "../components/PageSpinner";

import * as Server from "../Server";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import * as GeneralFunctions from "../GeneralFunctions";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      userCount: {},
      postCount: {},
      workoutClassCount: {},
      rewardCount: {},
      notificationCount: {},
      recent10Users: [],
      recent10Posts: [],
    };
    this.authUser = null;
  }

  componentDidMount() {
    this.getDashboardDetails();
  }

  getDashboardDetails = async () => {
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: "admin/dashboard",
        method: "GET",
      });
      if (response) {
        this.setState({ showLoader: false });
        this.setState({
          userCount: response.userCount,
          postCount: response.postCount,
          workoutClassCount: response.workoutClassCount,
          rewardCount: response.rewardCount,
          notificationCount: response.notificationCount,
          recent10Users: response.recent10Users,
          recent10Posts: response.recent10Posts,
        });
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  render() {
    return (
      <div>
        <PageSpinner showLoader={this.state.showLoader} />
        <Page
          title="Dashboard"
          breadcrumbs={[{ name: "Dashboard", active: true }]}
        >
          <Row>
            <Col lg={4} md={4} sm={6} xs={12}>
              <Card body outline>
                <CardTitle className="d-flex justify-content-between">
                  <div>
                    <strong>Users</strong>
                  </div>
                  <div className="text-secondary" style={{ fontSize: "20px" }}>
                    <Badge color="info" pill>
                      {this.state.userCount.total}
                    </Badge>
                  </div>
                </CardTitle>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Pending
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="warning" pill>
                      {this.state.userCount.pending}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Approved
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="success" pill>
                      {this.state.userCount.approved}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Rejected
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="danger" pill>
                      {this.state.userCount.rejected}
                    </Badge>
                  </Typography>
                </CardText>
              </Card>
            </Col>

            <Col lg={4} md={4} sm={6} xs={12}>
              <Card body outline>
                <CardTitle className="d-flex justify-content-between">
                  <div>
                    <strong>Posts</strong>
                  </div>
                  <div className="text-secondary" style={{ fontSize: "20px" }}>
                    <Badge color="info" pill>
                      {this.state.postCount.total}
                    </Badge>
                  </div>
                </CardTitle>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Pending
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="warning" pill>
                      {this.state.postCount.pending}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Approved
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="success" pill>
                      {this.state.postCount.approved}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Rejected
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="danger" pill>
                      {this.state.postCount.rejected}
                    </Badge>
                  </Typography>
                </CardText>
              </Card>
            </Col>

            <Col lg={4} md={4} sm={6} xs={12}>
              <Card body outline style={{ paddingBottom: "0px" }}>
                <CardTitle className="d-flex justify-content-between">
                  <div>
                    <strong>Workout Classes</strong>
                  </div>
                  <div className="text-secondary" style={{ fontSize: "20px" }}>
                    <Badge color="info" pill>
                      {this.state.workoutClassCount.total}
                    </Badge>
                  </div>
                </CardTitle>
                <Row>
                  <Col lg={6} md={4} sm={6} xs={12}>
                    <CardText
                      tag="div"
                      className="d-flex justify-content-between"
                    >
                      <Typography
                        tag="span"
                        className="text-left text-muted small"
                      >
                        Pending
                      </Typography>
                      <Typography
                        tag="span"
                        className="text-right text-muted small"
                      >
                        <Badge color="warning" pill>
                          {this.state.workoutClassCount.pending}
                        </Badge>
                      </Typography>
                    </CardText>
                    <CardText
                      tag="div"
                      className="d-flex justify-content-between"
                    >
                      <Typography
                        tag="span"
                        className="text-left text-muted small"
                      >
                        Approved
                      </Typography>
                      <Typography
                        tag="span"
                        className="text-right text-muted small"
                      >
                        <Badge color="success" pill>
                          {this.state.workoutClassCount.approved}
                        </Badge>
                      </Typography>
                    </CardText>
                    <CardText
                      tag="div"
                      className="d-flex justify-content-between"
                    >
                      <Typography
                        tag="span"
                        className="text-left text-muted small"
                      >
                        Rejected
                      </Typography>
                      <Typography
                        tag="span"
                        className="text-right text-muted small"
                      >
                        <Badge color="danger" pill>
                          {this.state.workoutClassCount.rejected}
                        </Badge>
                      </Typography>
                    </CardText>
                  </Col>
                  <Col lg={6} md={4} sm={6} xs={12}>
                    <CardText
                      tag="div"
                      className="d-flex justify-content-between"
                    >
                      <Typography
                        tag="span"
                        className="text-left text-muted small"
                      >
                        Cancelled
                      </Typography>
                      <Typography
                        tag="span"
                        className="text-right text-muted small"
                      >
                        <Badge color="danger" pill>
                          {this.state.workoutClassCount.cancelled}
                        </Badge>
                      </Typography>
                    </CardText>
                    <CardText
                      tag="div"
                      className="d-flex justify-content-between"
                    >
                      <Typography
                        tag="span"
                        className="text-left text-muted small"
                      >
                        Live
                      </Typography>
                      <Typography
                        tag="span"
                        className="text-right text-muted small"
                      >
                        <Badge color="success" pill>
                          {this.state.workoutClassCount.live}
                        </Badge>
                      </Typography>
                    </CardText>
                    <CardText
                      tag="div"
                      className="d-flex justify-content-between"
                    >
                      <Typography
                        tag="span"
                        className="text-left text-muted small"
                      >
                        Completed
                      </Typography>
                      <Typography
                        tag="span"
                        className="text-right text-muted small"
                      >
                        <Badge color="success" pill>
                          {this.state.workoutClassCount.completed}
                        </Badge>
                      </Typography>
                    </CardText>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg={4} md={4} sm={6} xs={12}>
              <Card body outline>
                <CardTitle className="d-flex justify-content-between">
                  <div>
                    <strong>Rewards</strong>
                  </div>
                  <div className="text-secondary" style={{ fontSize: "20px" }}>
                    <Badge color="info" pill>
                      {this.state.rewardCount.total}
                    </Badge>
                  </div>
                </CardTitle>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Pending
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="warning" pill>
                      {this.state.rewardCount.pending}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Approved
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="success" pill>
                      {this.state.rewardCount.approved}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Rejected
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="danger" pill>
                      {this.state.rewardCount.rejected}
                    </Badge>
                  </Typography>
                </CardText>
              </Card>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12}>
              <Card body outline>
                <CardTitle className="d-flex justify-content-between">
                  <div>
                    <strong>Notifications</strong>
                  </div>
                  <div className="text-secondary" style={{ fontSize: "20px" }}>
                    <Badge color="info" pill>
                      {this.state.notificationCount.total}
                    </Badge>
                  </div>
                </CardTitle>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Pending
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="warning" pill>
                      {this.state.notificationCount.pending}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Sent
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="success" pill>
                      {this.state.notificationCount.sent}
                    </Badge>
                  </Typography>
                </CardText>
                <CardText tag="div" className="d-flex justify-content-between">
                  <Typography tag="span" className="text-left text-muted small">
                    Failed
                  </Typography>
                  <Typography
                    tag="span"
                    className="text-right text-muted small"
                  >
                    <Badge color="danger" pill>
                      {this.state.notificationCount.failed}
                    </Badge>
                  </Typography>
                </CardText>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between">
                  Recent Users
                </CardHeader>
                <CardBody>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.recent10Users &&
                      this.state.recent10Users.length > 0 ? (
                        this.state.recent10Users.map((user, index) => (
                          <tr key={index}>
                            <td>
                              {user.firstName} {user.middleName || ""}{" "}
                              {user.lastName || ""}
                            </td>
                            <td>{user.email}</td>
                            <td>
                              {user.accountType == "pinger" && "Pinger"}
                              {user.accountType == "ponger" && "Ponger"}
                              {user.accountType == "basic" && "Enthusiast"}
                              {user.accountType == "mpk" && "MPK"}
                            </td>
                            <td>
                              {
                                <Moment format="MM/DD/YYYY">
                                  {user.createdAt}
                                </Moment>
                              }
                            </td>
                            <td>
                              {user.status == "pending" && (
                                <Badge color="warning" pill>
                                  {GeneralFunctions.capitalize(user.status)}
                                </Badge>
                              )}
                              {user.status == "approved" && (
                                <Badge color="success" pill>
                                  {GeneralFunctions.capitalize(user.status)}
                                </Badge>
                              )}
                              {user.status == "rejected" && (
                                <Badge color="danger" pill>
                                  {GeneralFunctions.capitalize(user.status)}
                                </Badge>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No users found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between">
                  Recent Posts
                </CardHeader>
                <CardBody>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Type</th>
                        <th colSpan="2">Posted By</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.recent10Posts &&
                      this.state.recent10Posts.length > 0 ? (
                        this.state.recent10Posts.map((post, index) => (
                          <tr key={index}>
                            <td>{post.title}</td>
                            <td>{GeneralFunctions.capitalize(post.type)}</td>
                            <td>
                              {post.owner.firstName}{" "}
                              {post.owner.middleName || ""}{" "}
                              {post.owner.lastName || ""}
                            </td>
                            <td></td>
                            <td>
                              {
                                <Moment format="MM/DD/YYYY">
                                  {post.createdAt}
                                </Moment>
                              }
                            </td>
                            <td>
                              {post.status == "pending" && (
                                <Badge color="warning" pill>
                                  {GeneralFunctions.capitalize(post.status)}
                                </Badge>
                              )}
                              {post.status == "approved" && (
                                <Badge color="success" pill>
                                  {GeneralFunctions.capitalize(post.status)}
                                </Badge>
                              )}
                              {post.status == "rejected" && (
                                <Badge color="danger" pill>
                                  {GeneralFunctions.capitalize(post.status)}
                                </Badge>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No posts found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <NotificationSystem
            dismissible={false}
            ref={(notificationSystem) =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </Page>
      </div>
    );
  }
}
export default Dashboard;
