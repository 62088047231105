import React from "react";
import {
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Page from "../components/Page";

import * as Server from "../Server";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import PageSpinner from "../components/PageSpinner";

class Lounge extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lounges: [],
      selectedLounge: {
        _id: "",
        title: "",
      },
      selectedLoungeType: "",
      changeLoungeVideoModel: false,
      loungeVideoType: "",
      loungeVideo: "",
      typeValid: false,
      loungeValid: false,
      formValid: false,
      errors: {
        type: "",
        lounge: "",
      },
    };

    this.changeLoungeVideoModalToggle = this.changeLoungeVideoModalToggle.bind(this);
  }

  componentDidMount() {
    this.getLounge("");
  }

  handleTypeChane(type) {
    this.getLounge(type);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let typeValid = this.state.typeValid;
    let loungeValid = this.state.loungeValid;

    switch (fieldName) {
      case "type":
        typeValid = value.length !== 0;
        fieldValidationErrors.type = typeValid ? "" : "*Required";
        break;
      case "lounge":
        loungeValid = value.length !== 0;
        fieldValidationErrors.lounge = loungeValid ? "" : "*Required";
        break;

      default:
        break;
    }

    this.setState(
      {
        errors: fieldValidationErrors,
        typeValid: typeValid,
        loungeValid: loungeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.typeValid && this.state.loungeValid,
    });
  }

  changeLoungeVideo = async () => {
    this.setState({ loungeVideoType: "", loungeVideo: "" });
    this.changeLoungeVideoModalToggle();
  };

  async setLounge(e) {
    e.preventDefault();
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: "admin/setLounge",
        method: "POST",
        data: { _id: this.state.loungeVideo, type: this.state.loungeVideoType },
      });
      if (response) {
        this.setState({ showLoader: false });
        this.notificationSystem.addNotification({
          message: response.message,
          level: "success",
        });
        this.changeLoungeVideoModalToggle();
        this.setState({
          loungeVideoType: "",
          loungeVideo: "",
          typeValid: false,
          loungeValid: false,
          formValid: false,
          errors: {
            type: "",
            lounge: "",
          },
        });
        this.getLounge("");
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  }

  changeLoungeVideoModalToggle() {
    this.setState((prevState) => ({
      changeLoungeVideoModel: !prevState.changeLoungeVideoModel,
    }));
  }

  async getLounge(type) {
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: `admin/getLounge?type=${type}`,
        method: "GET",
      });
      this.setState({ showLoader: false });
      this.setState({
        lounges: response.lounges,
        selectedLounge: response.selectedLounge,
        selectedLoungeType: response.selectedType,
      });
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  }

  render() {
    return (
      <div>
        <PageSpinner showLoader={this.state.showLoader} />
        <Page title="Lounge" breadcrumbs={[{ name: "Lounge", active: true }]}>
          <Card>
            <CardHeader>
              Lounge
            </CardHeader>
            <CardBody>
              <Row>
                <Col xl={12} lg={6} md={6}>
                  Type :
                  <span className="p-2  ">
                    {this.state.selectedLoungeType &&
                      this.state.selectedLoungeType == "post"
                      ? "VOD"
                      : "Live Stream"}
                  </span>
                </Col>
                <Col xl={12} lg={6} md={6}>
                  Lounge Video :
                  <span className="p-2  ">
                    {this.state.selectedLounge &&
                      this.state.selectedLounge.title}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <FormGroup className="d-flex justify-content-center w-100 pt-3">
                    <Button onClick={(event) => this.changeLoungeVideo()}>
                      Change Lounge Video
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Modal
            isOpen={this.state.changeLoungeVideoModel}
            toggle={this.changeLoungeVideoModalToggle}
            className="modal-lg"
          >
            <ModalHeader toggle={this.changeLoungeVideoModalToggle}>
              Change Lounge Video
            </ModalHeader>
            <Form>
              <ModalBody>
                <ModalBody>
                  <Row>
                    <Col xl={12} lg={12} md={12}>
                      <FormGroup row>
                        <Label for="exampleEmail" sm={3}>
                          Type
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="select"
                            name="type"
                            value={this.state.loungeVideoType}
                            onChange={(event) => {
                              event.persist();
                              this.handleUserInput(event);
                              this.handleTypeChane(event.target.value);
                              this.setState({
                                loungeVideoType: event.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            <option value="workoutClass">Live Stream</option>
                            <option value="post">VOD</option>
                          </Input>
                          {!this.state.typeValid ? (
                            <span className="float-right text-danger">
                              <small>{this.state.errors.type}</small>
                            </span>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                      <FormGroup row>
                        <Label for="exampleEmail" sm={3}>
                          Lounge Video
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="select"
                            name="lounge"
                            value={this.state.loungeVideo}
                            onChange={(event) => {
                              event.persist();
                              this.handleUserInput(event);
                              this.setState({
                                loungeVideo: event.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            {this.state.lounges && this.state.lounges.length > 0
                              ? this.state.lounges.map((lounge, index) => (
                                <option key={index} value={lounge._id}>
                                  {lounge.title}
                                </option>
                              ))
                              : null}
                          </Input>
                          {!this.state.typeValid ? (
                            <span className="float-right text-danger">
                              <small>{this.state.errors.type}</small>
                            </span>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.changeLoungeVideoModalToggle}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={!this.state.formValid}
                  onClick={(event) => this.setLounge(event)}
                  type="submit"
                >
                  Set Lounge
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <NotificationSystem
            dismissible={false}
            ref={(notificationSystem) =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </Page>
      </div>
    );
  }
}
export default Lounge;
