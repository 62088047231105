import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Badge, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import * as Server from '../Server';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DeletedPosts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            collapseFilter: false,
            posts: [],
            allCategories: [],
            categories: [],
            subCategories: [],
            filter: {
                type: "",
                level: "",
                category: "",
                subCategory: "",
                status: "",
                deletedBy: "",
                fromDate: "",
                fromDateString: "",
                toDate: "",
                toDateString: ""
            },
            page: 1,
            limit: 10,
            totalPosts: 0,
            pages: 0,
            pageNumbers: [],
            postDetail: {}
        };
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('logs')) this.props.history.push('/dashboard');
        this.getCategories();
        this.getDeletedPostsDetails();
    }

    getCategories = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: '/categories',
                method: 'GET',
            });
            if (response) {
                this.setState({
                    showLoader: false,
                    allCategories: response.categories
                });
            }
        }
        catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    getDeletedPostsDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let filter = this.state.filter;
            let page = this.state.page;
            let limit = this.state.limit;
            let response = await Server.request({
                url: 'admin/log/postDeleted',
                method: 'POST',
                data: { filter, page, limit }
            });
            this.setState({ showLoader: false });
            this.setState({ posts: response.posts, totalPosts: response.totalPosts, pages: response.pages });
            let pageNumbers = [];
            for (let page = 1; page <= response.pages; page++) {
                pageNumbers.push(page);
            }
            this.setState({ pageNumbers: pageNumbers });
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    search() {
        this.setState({ page: parseInt(1, 10) }, () => {
            this.getDeletedPostsDetails();
        });
    }

    refreshPagination = async (page, limit) => {
        this.setState({ page: parseInt(page, 10), limit: limit }, () => {
            this.getDeletedPostsDetails();
        });
    }

    getPostInfo = async (post) => {
        this.setState({ postDetail: post })
    }

    closeModal = async () => {
        this.setState({ postDetail: {} })
    }

    getSubCategory = (categoryId) => {
        if (categoryId) {
            let category = this.state.categories.find(obj => obj._id === categoryId);
            this.setState({ subCategories: category.subCats });
        }
        else {
            this.setState({ subCategories: [] });
        }
    }
    render() {
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Deleted Posts"
                    breadcrumbs={[{ name: 'Deleted Posts', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>
                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.search() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="type" sm={3}>
                                                            Type
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="type" onChange={(event) => {
                                                                event.persist();
                                                                let categories = [];
                                                                if (event.target.value) {
                                                                    categories = this.state.allCategories.find(obj => obj._id == event.target.value).cats;
                                                                }
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        type: event.target.value
                                                                    },
                                                                    subCategories: [],
                                                                    categories: categories
                                                                }), () => {
                                                                    if (this.state.filter.type === '') {
                                                                        this.setState({
                                                                            filter: {
                                                                                ...this.state.filter,
                                                                                level: "",
                                                                                category: "",
                                                                                subCategory: "",
                                                                            }
                                                                        })
                                                                    }
                                                                });
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="quickpeeks">Quick Peeks</option>
                                                                <option value="deepdives">Deep Dives</option>
                                                                <option value="giggles">Giggles</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="level" sm={3}>
                                                            Level
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="level" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        level: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                {
                                                                    (this.state.filter.type === 'quickpeeks' || this.state.filter.type === 'deepdives') &&
                                                                    <React.Fragment>
                                                                        <option value="beginner">Beginner</option>
                                                                        <option value="intermediate">Intermediate</option>
                                                                        <option value="advanced">Advanced</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="All">All</option>
                                                                    </React.Fragment>
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="category" sm={3}>
                                                            Category
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="select"
                                                                name="category"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            category: event.target.value
                                                                        }
                                                                    }),() => {
                                                                        if (this.state.filter.category === '') {
                                                                            this.setState({
                                                                                filter: {
                                                                                    ...this.state.filter,
                                                                                    subCategory: "",
                                                                                }
                                                                            })
                                                                        }
                                                                    });
                                                                    this.getSubCategory(event.target.value);
                                                                }}>
                                                                <option value="">Select</option>
                                                                {this.state.categories.map((category) => {
                                                                    return (<option value={category._id} key={category._id}>{category.name}</option>)
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="subCategories" sm={3}>
                                                            Sub-Category
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="select"
                                                                name="subCategories"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            subCategory: event.target.value
                                                                        }
                                                                    }));
                                                                }
                                                                }>
                                                                <option value="">Select</option>
                                                                {this.state.subCategories.map((subcategory) => {
                                                                    return (<option value={subcategory._id} key={subcategory._id}>{subcategory.name}</option>)
                                                                })}
                                                            </Input>

                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="status" sm={3}>
                                                            Status
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="status" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        status: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="approved">Approved</option>
                                                                <option value="rejected">Rejected</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={3}>
                                                            From Date
                                                        </Label>
                                                        <Col sm={9}>
                                                            <DatePicker
                                                                name="fromDate"
                                                                placeholderText="From Date"
                                                                className="form-control"
                                                                selectsStart
                                                                selected={this.state.filter.fromDate}
                                                                startDate={this.state.filter.fromDate}
                                                                maxDate={this.state.filter.toDate}
                                                                endDate={this.state.filter.toDate}
                                                                onChange={(date) => {
                                                                    let fromDateString = "";
                                                                    if (date != null) {
                                                                        fromDateString = date.getFullYear() + "-" + ((date.getMonth() + 1) <= 9 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "-" + (date.getDate() <= 9 ? ("0" + date.getDate()) : date.getDate());
                                                                    }
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            fromDate: date,
                                                                            fromDateString: fromDateString
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={3}>
                                                            To Date
                                                        </Label>
                                                        <Col sm={9}>
                                                            <DatePicker
                                                                name="toDate"
                                                                placeholderText="To Date"
                                                                className="form-control"
                                                                selectsEnd
                                                                selected={this.state.filter.toDate}
                                                                startDate={this.state.filter.fromDate}
                                                                endDate={this.state.filter.toDate}
                                                                minDate={this.state.filter.fromDate}
                                                                onChange={(date) => {
                                                                    let toDateString = '';
                                                                    if (date != null) {
                                                                        toDateString = date.getFullYear() + "-" + ((date.getMonth() + 1) <= 9 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "-" + (date.getDate() <= 9 ? ("0" + date.getDate()) : date.getDate());
                                                                    }
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            toDate: date,
                                                                            toDateString: toDateString
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={3}>
                                                            Deleted By
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="deletedBy" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        deletedBy: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="admin">Admin</option>
                                                                <option value="user">User</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardHeader>
                                    <Row>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex">
                                                <div sm={4} className="mt-2 pr-2"><Label>Shown</Label></div>
                                                <div sm={4}>
                                                    <Input type="select" name="pageLimit" onChange={(event) => { this.refreshPagination(1, event.target.value) }}>
                                                        <option value={10}>10</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Input>
                                                </div>
                                                <div sm={4} className="mt-2 pl-2"><Label>entries</Label></div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-2 pr-2">
                                                    <Label>Showing {!this.state.totalPosts ? 0 : ((this.state.page * this.state.limit) - (this.state.limit - 1))}  to {(this.state.page * this.state.limit) > this.state.totalPosts ? this.state.totalPosts : (this.state.page * this.state.limit)} of {this.state.totalPosts}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-end">
                                                <div sm={4} className="pr-2"><Button className="rounded-circle" disabled={this.state.page <= 1} onClick={(event) => this.refreshPagination(this.state.page - 1, this.state.limit)}><MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft></Button></div>
                                                <div sm={4}>
                                                    <Input type="select" value={this.state.page} name="filter" onChange={(event) => { this.refreshPagination(event.target.value, this.state.limit) }}>
                                                        {
                                                            this.state.pageNumbers.map((pageNumber, index) => (
                                                                <option value={pageNumber} key={index}>{pageNumber}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                                <div sm={4} className="pl-2"><Button className="rounded-circle" disabled={this.state.page >= this.state.pages} onClick={(event) => this.refreshPagination(this.state.page + 1, this.state.limit)}><MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight></Button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Type</th>
                                                <th>Level</th>
                                                <th>Category</th>
                                                <th>SubCategory</th>
                                                <th>Deleted By</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.posts && this.state.posts.length > 0
                                                    ? this.state.posts.map((post, index) => (
                                                        <tr key={index}>
                                                            <td><Button color="link" style={{ textAlign: 'left' }} onClick={(event) => this.getPostInfo(post)}>{post.title}</Button></td>
                                                            <td>{GeneralFunctions.capitalize(post.type)}</td>
                                                            <td>{post.type != 'giggles' && GeneralFunctions.capitalize(post.level)}</td>
                                                            <td>{post.type != 'giggles' && post.category}</td>
                                                            <td>{post.type != 'giggles' && post.subCategory}</td>
                                                            <td>{GeneralFunctions.capitalize(post.deletedBy)}</td>
                                                            <td>{<Moment format="MM/DD/YYYY">{post.createdAt}</Moment>}</td>
                                                            <td>
                                                                {
                                                                    post.status == 'pending' &&
                                                                    <Badge color="warning" pill>{GeneralFunctions.capitalize(post.status)}</Badge>
                                                                }
                                                                {
                                                                    post.status == 'approved' &&
                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(post.status)}</Badge>
                                                                }
                                                                {
                                                                    post.status == 'rejected' &&
                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(post.status)}</Badge>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="8" className="text-center">No deleted posts found.</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    this.state.postDetail && this.state.postDetail._id ?
                                        <Modal
                                            isOpen={true}
                                            toggle={(event) => this.closeModal()}
                                            className="modal-lg">
                                            <ModalHeader toggle={(event) => this.closeModal()}>Post Details</ModalHeader>
                                            <ModalBody>
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Row>
                                                            <Label sm={3}>Title :</Label>
                                                            <Label sm={9}>{this.state.postDetail.title}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Type :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.type)}</Label>
                                                        </Row>
                                                        {
                                                            this.state.postDetail.type != 'giggles' &&
                                                            <Row>
                                                                <Label sm={3}>Level :</Label>
                                                                <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.level)}</Label>
                                                            </Row>
                                                        }
                                                        {
                                                            this.state.postDetail.type != 'giggles' &&
                                                            <React.Fragment>
                                                                <Row>
                                                                    <Label sm={3}>Category :</Label>
                                                                    <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.category)}</Label>
                                                                </Row>
                                                                <Row>
                                                                    <Label sm={3}>Sub Category :</Label>
                                                                    <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.subCategory)}</Label>
                                                                </Row>
                                                            </React.Fragment>
                                                        }
                                                        <Row>
                                                            <Label sm={3}>Posted By :</Label>
                                                            <Label sm={9}>{this.state.postDetail.ownerName}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Date :</Label>
                                                            <Label sm={9}>{<Moment format="MM/DD/YYYY">{this.state.postDetail.createdAt}</Moment>}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Status :</Label>
                                                            <Label sm={9}>
                                                                {
                                                                    this.state.postDetail.status == 'pending' &&
                                                                    <Badge color="warning" pill>{GeneralFunctions.capitalize(this.state.postDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.postDetail.status == 'approved' &&
                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.postDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.postDetail.status == 'rejected' &&
                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(this.state.postDetail.status)}</Badge>
                                                                }
                                                            </Label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.closeModal()}>Cancel</Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div >
        );
    }
}
export default DeletedPosts;
