import logo200Image from '../../assets/img/logo/logo.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import {
  MdDashboard,
  MdNotificationsActive,
  MdSupervisorAccount,
  MdPlayCircleFilled,
  MdMonetizationOn,
  MdPersonAdd,
  MdVpnKey,
  MdNoteAdd,
  MdExpandLess,
  MdExpandMore,
  MdVideocam,
  MdWeekend,
  MdCollections
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Collapse,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import * as GeneralFunctions from '../../GeneralFunctions';
const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [


  { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard, permission: 'dashboard' },
  { to: '/lounges', name: 'Lounges', exact: true, Icon: MdWeekend, permission: 'lounges' },
  { to: '/users', name: 'Users', exact: true, Icon: MdSupervisorAccount, permission: 'users' },
  { to: '/mpkUsers', name: 'MPK Users', exact: false, Icon: MdSupervisorAccount, permission: 'mpkUsers' },
  { to: '/videos', name: 'Videos', exact: true, Icon: MdPlayCircleFilled, permission: 'posts' },
  { to: '/liveWorkouts', name: 'Live Workouts', exact: true, Icon: MdVideocam, permission: 'workoutClasses' },
  { to: '/takeaways', name: 'Takeaways', exact: true, Icon: MdCollections, permission: 'takeaways' },
  { to: '/rewards', name: 'Rewards', exact: true, Icon: MdMonetizationOn, permission: 'rewards' },
  { to: '/notifications', name: 'Notifications', exact: true, Icon: MdNotificationsActive, permission: 'notifications' },
  { to: '/admins', name: 'Admins', exact: true, Icon: MdPersonAdd, permission: 'admins' },
 


  { to: '/rolesAndPermissions', name: 'Roles and Permissions', exact: true, Icon: MdVpnKey, permission: 'rolesAndPermissions' },
  {
    to: '', name: 'Logs', exact: true, Icon: MdNoteAdd, permission: 'logs',
    submenu: [
      {
        to: '/deletedUsers', name: 'Deleted Users', exact: true
      },
      {
        to: '/deletedPosts', name: 'Deleted Posts', exact: true
      }
    ]
  },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: true,
      collapsed: false
    };
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    let permissions = GeneralFunctions.getUserPermission();
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <BSNavLink
              tag={NavLink}
              to={'/dashboard'}
              exact={true}
              style={{ padding: 0 }}
            >
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              <span className="text-white"
                height="40">
                THE AB LAB<sup>Ⓡ</sup> dAPP
              </span>
            </BSNavLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon, permission, submenu }, index) => (
              permission == 'dashboard' || permissions.includes(permission) ?
                <NavItem key={index} className={bem.e('nav-item')}>
                  {
                    to &&
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  }
                  {
                    !to &&
                    <React.Fragment>
                      <a className="nav-link" style={{ cursor: 'pointer' }} id={`navItem-${name}-${index}`} onClick={(event) => this.setState({ collapsed: !this.state.collapsed })}>
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="" style={{ width: '100%' }}>{name}</span>
                        {
                          this.state.collapsed ?
                            <MdExpandLess style={{ height: 30, width: 30 }} className={bem.e('nav-item-icon')}></MdExpandLess>
                            : <MdExpandMore style={{ height: 30, width: 30 }} className={bem.e('nav-item-icon')} ></MdExpandMore>
                        }
                      </a>
                      <Collapse isOpen={this.state.collapsed} navbar style={{ marginLeft: 30 }}>
                        <Nav navbar>
                          {submenu.map(({ to, name, exact }, index) => (
                            <BSNavLink
                              key={index}
                              id={`navItem-${name}-${index}`}
                              tag={NavLink}
                              to={to}
                              activeClassName="active"
                              exact={exact}
                            >
                              <span className="" style={{ paddingLeft: 10 }}>{name}</span>
                            </BSNavLink>
                          ))}
                        </Nav>
                      </Collapse>
                    </React.Fragment>
                  }
                </NavItem>
                : null
            ))}

          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
