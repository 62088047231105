import React from "react";
import {
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Page from "../components/Page";

import {
  MdFilterList,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdDeleteForever,
  MdModeEdit,
} from "react-icons/md";
import * as Server from "../Server";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import * as GeneralFunctions from "../GeneralFunctions";
import PageSpinner from "../components/PageSpinner";
import Badge from "reactstrap/lib/Badge";
import ReactPlayer from "react-player";

class Takeaways extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      collapseFilter: false,
      createTakeawayModal: false,
      detailsTakeawayModal: false,
      mintTakeawayModal: false,
      showVideo: false,
      showImage: false,
      showAudio: false,
      takeaways: [],
      filter: {
        title: "",
        mediaType: "",
        mintTotal: "",
        mintAvailable: "",
      },
      takeaway: {
        title: "",
        message: "",
        mediaType: "",
        link: "",
      },
      currentTakeaway: {
        test: "",
      },
      mint: {
        _id: "",
        mintTotal: "",
      },
      titleValid: false,
      messageValid: false,
      mediaTypeValid: false,
      mediaValid: false,
      linkValid: false,
      coverPhotoValid: false,
      mintValid: false,
      formMintValid: false,
      formValid: false,
      isMediaValid: false,
      mintId: "",
      errors: {
        title: "",
        message: "",
        mediaType: "",
        media: "",
        coverPhoto: "",
        link: "",
      },
      page: 1,
      limit: 10,
      totalTakeaways: 0,
      pages: 0,
      pageNumbers: [],
      finalValidate: false,
    };

    this.createTakeawayModalToggle = this.createTakeawayModalToggle.bind(this);
    this.detailsTakeawayModalToggle = this.detailsTakeawayModalToggle.bind(this);
    this.mintTakeawayModalToggle = this.mintTakeawayModalToggle.bind(this);
  }

  componentDidMount() {
    let permissions = GeneralFunctions.getUserPermission();
    if (!permissions.includes("takeaways")) this.props.history.push("/dashboard");
    this.getTakeawaysDetails();
  }

  getTakeawaysDetails = async () => {
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: "admin/takeaway/search",
        method: "POST",
        data: {
          filter: this.state.filter,
        },
      });
      this.setState({ showLoader: false });
      this.setState({
        takeaways: response.takeaways,
        totalTakeaways: response.totalTakeaways,
        pages: response.pages,
      });
      let pageNumbers = [];
      for (let page = 1; page <= response.pages; page++) {
        pageNumbers.push(page);
      }
      this.setState({ pageNumbers: pageNumbers });
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  createTakeaway = async () => {
    this.setState({ takeaway: {} });
    this.createTakeawayModalToggle();
  };

  mintTakeaway = async () => {
    this.setState({ mint: {} });
    this.mintTakeawayModalToggle();
  };

  onCreateTakeawayMintSubmit = async (event, id) => {
    event.preventDefault();
    try {
      let url = "/admin/takeaway/mint";
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: "/admin/takeaway/mint",
        method: "POST",
        data: { _id: this.state.mintId, mintTotal: this.state.mint.mintTotal },
      });
      if (response) {
        this.setState({ showLoader: false });
        this.notificationSystem.addNotification({
          message: "Takeaway minted successfully.",
          level: "success",
        });
        this.mintTakeawayModalToggle();
        this.getTakeawaysDetails();
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onCreateTakeawaySubmit = async (event) => {
    event.preventDefault();
    try {
      this.setState({ showLoader: true });
      var formData = new FormData();
      formData.append("title", this.state.takeaway.title);
      formData.append("coverPhoto", this.state.takeaway.coverPhoto);
      formData.append("media", this.state.takeaway.media);
      formData.append("message", this.state.takeaway.message);
      formData.append("mediaType", this.state.takeaway.mediaType);
      formData.append("link", this.state.takeaway.link || "");
      let response = await Server.request({
        url: "/admin/takeaway",
        method: "POST",
        data: formData,
      });
      if (response) {
        this.setState({ showLoader: false });
        this.notificationSystem.addNotification({
          message: response.message,
          level: "success",
        });
        this.createTakeawayModalToggle();
        this.getTakeawaysDetails();
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  search() {
    this.setState({ page: parseInt(1, 10) }, () => {
      this.getTakeawaysDetails();
    });
  }

  refreshPagination = async (page, limit) => {
    this.setState({ page: parseInt(page, 10), limit: limit }, () => {
      this.getTakeawaysDetails();
    });
  };

  componentWillMount() {
    this.getTakeawaysDetails();
  }

  showDetails(takeaway) {
    this.setState({ currentTakeaway: takeaway, showAudio: false, showImage: false, showVideo: false });
    this.detailsTakeawayModalToggle();
  }

  createTakeawayModalToggle() {
    this.setState((prevState) => ({
      createTakeawayModal: !prevState.createTakeawayModal,
    }));
  }
  detailsTakeawayModalToggle() {
    this.setState((prevState) => ({
      detailsTakeawayModal: !prevState.detailsTakeawayModal,
    }));
  }

  mintTakeawayModalToggle() {
    this.setState((prevState) => ({
      mintTakeawayModal: !prevState.mintTakeawayModal,
    }));
  }

  mediaValidation(media) {
    console.log(this.state.takeaway.mediaType);
    if (this.state.takeaway.mediaType === "image") {
      if (media) {
        if (
          media.type != "image/jpeg" &&
          media.type != "image/jpg" &&
          media.type != "image/png"
        ) {
          this.setState({ finalValidate: false });

          this.notificationSystem.addNotification({
            message: "Please select only valid JPEG or PNG image format.",
            level: "error",
          });
          return;
        } else {
          console.log("else");
          this.setState({ finalValidate: true });
        }
      }
    }
    if (this.state.takeaway.mediaType === "video") {
      if (media) {
        if (media.type != "video/quicktime" && media.type != "video/mp4") {
          this.setState({ finalValidate: false });

          this.notificationSystem.addNotification({
            message: "Please select only valid MP4 or MOV video format.",
            level: "error",
          });
          return;
        } else {
          console.log("else");
          this.setState({ finalValidate: true });
        }
      }
    }
    if (this.state.takeaway.mediaType === "audio") {
      if (media) {
        if (media.type != "audio/mpeg") {
          this.setState({ finalValidate: false });

          this.notificationSystem.addNotification({
            message: "Please select only valid MP3 audio format.",
            level: "error",
          });
          return;
        } else {
          console.log("else");
          this.setState({ finalValidate: true });
        }
      }
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let titleValid = this.state.titleValid;
    let messageValid = this.state.messageValid;
    let mediaTypeValid = this.state.mediaTypeValid;
    let mediaValid = this.state.mediaValid;
    let linkValid = this.state.linkValid;
    let coverPhotoValid = this.state.coverPhotoValid;
    let mintValid = this.state.mintValid;
    switch (fieldName) {
      case "mint":
        if (value.length === 0) {
          mintValid = false;
          fieldValidationErrors.mint = mintValid ? "" : "*Required";
        } else if (parseInt(value) < 0) {
          mintValid = false;
          fieldValidationErrors.mint = mintValid ? "" : "Please enter a positive number.";
        } else if (value.split(".").length > 1) {
          mintValid = false;
          fieldValidationErrors.mint = mintValid ? "" : "Please enter a positive number.";
        } else if (!parseInt(value) > 0) {
          mintValid = false;
          fieldValidationErrors.mint = mintValid ? "" : "Please enter a positive number.";
        } else {
          mintValid = true;
        }
        break;
      case "title":
        titleValid = value.length !== 0;
        fieldValidationErrors.title = titleValid ? "" : "*Required";
        break;
      case "message":
        messageValid = value.length !== 0;
        fieldValidationErrors.message = messageValid ? "" : "*Required";
        break;
      case "coverPhoto":
        coverPhotoValid = value.length !== 0;
        fieldValidationErrors.coverPhoto = coverPhotoValid ? "" : "*Required";
        break;
      case "mediaType":
        mediaTypeValid = value.length !== 0;
        fieldValidationErrors.mediaType = mediaTypeValid ? "" : "*Required";
        break;
      case "media":
        mediaValid = value.length !== 0;
        fieldValidationErrors.media = mediaValid ? "" : "*Required";
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        titleValid: titleValid,
        messageValid: messageValid,
        coverPhotoValid: coverPhotoValid,
        mediaTypeValid: mediaTypeValid,
        mediaValid: mediaValid,
        mintValid: mintValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.titleValid &&
        this.state.messageValid &&
        this.state.mediaTypeValid &&
        this.state.mediaValid &&
        this.state.coverPhotoValid,
    });
    this.setState({
      formMintValid: this.state.mintValid,
    });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleMintInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  render() {
    return (
      <div>
        <PageSpinner showLoader={this.state.showLoader} />
        <Page title="Takeaways" breadcrumbs={[{ name: "Takeaways", active: true }]}>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader
                  onClick={(event) => {
                    this.setState({
                      collapseFilter: !this.state.collapseFilter,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <React.Fragment>
                    <MdFilterList
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></MdFilterList>
                    Filter
                    {this.state.collapseFilter ? (
                      <MdKeyboardArrowUp
                        style={{ fontSize: 14, float: "right" }}
                      ></MdKeyboardArrowUp>
                    ) : (
                        <MdKeyboardArrowDown
                          style={{ fontSize: 14, float: "right" }}
                        ></MdKeyboardArrowDown>
                      )}
                  </React.Fragment>
                </CardHeader>
                <Collapse isOpen={this.state.collapseFilter}>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleSelect" sm={2}>
                              Title
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="text"
                                name="title"
                                placeholder="Enter Title"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      title: event.target.value,
                                    },
                                  }));
                                }}
                              ></Input>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleEmail" sm={2}>
                              Media Type
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="select"
                                name="mediaType"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      mediaType: event.target.value,
                                    },
                                  }));
                                }}
                              >
                                <option value="">Select</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                                <option value="audio">Audio</option>
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>

                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleEmail" sm={2}>
                              Total Mint
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="number"
                                name="mintTotal"
                                placeholder="Enter Mint Total"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      mintTotal: parseInt(event.target.value),
                                    },
                                  }));
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleEmail" sm={2}>
                              Available Mint
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="number"
                                name="mintAvailable"
                                placeholder="Enter Mint Available"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      mintAvailable: parseInt(
                                        event.target.value
                                      ),
                                    },
                                  }));
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <FormGroup className="d-flex justify-content-center w-100">
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              this.search();
                            }}
                            style={{ width: "30%" }}
                            type="submit"
                          >
                            Search
                          </Button>
                        </FormGroup>
                      </Row>
                    </Form>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Button
                className="float-right"
                onClick={(event) => this.createTakeaway()}
                title="Create Takeaway"
              >
                Create Takeaway
              </Button> */}
              <Modal
                isOpen={this.state.detailsTakeawayModal}
                toggle={this.detailsTakeawayModalToggle}
                className="modal-lg"
              >
                <ModalHeader toggle={this.detailsTakeawayModalToggle}>
                  Takeaway Details
                </ModalHeader>
                <Form>
                  <ModalBody>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Row>
                          <Label sm={3}>Title :</Label>
                          <Label sm={9}>{this.state.currentTakeaway.title}</Label>
                        </Row>

                        <Row>
                          <Label sm={3}>Message :</Label>
                          <Label sm={9}>
                            {this.state.currentTakeaway.message}
                          </Label>
                        </Row>

                        <Row>
                          <Label sm={3}>Link :</Label>
                          <Label sm={9}><a href={this.state.currentTakeaway.link} target="_blank">{this.state.currentTakeaway.link}</a></Label>
                        </Row>
                        <Row>
                          <Label sm={3}>Cover Photo :</Label>
                          <Label sm={9}>
                            <img
                              src={`${process.env.REACT_APP_CLIENT_URL}/takeaway/${this.state.currentTakeaway._id}_coverPhoto${this.state.currentTakeaway.coverPhotoExt}`}
                              alt="Girl in a jacket"
                              width="130"
                              height="130"
                            />
                          </Label>
                        </Row>
                        <Row>
                          <Label sm={3}>Total Mint :</Label>
                          <Label sm={9}>
                            {this.state.currentTakeaway.mintTotal}
                          </Label>
                        </Row>

                        <Row>
                          <Label sm={3}>Available Mint :</Label>
                          <Label sm={9}>
                            {this.state.currentTakeaway.mintAvailable}
                          </Label>
                        </Row>

                        <Row>
                          <Label sm={3}>Media Type :</Label>
                          <Label sm={9}>
                            {GeneralFunctions.capitalize(
                              this.state.currentTakeaway.mediaType
                            )}
                          </Label>
                        </Row>
                        {this.state.currentTakeaway.mediaType == "video" && (
                          <Row>
                            <Label sm={3}>Media :</Label>
                            <Label sm={9}>
                              {!this.state.showVideo && (
                                <Button
                                  onClick={(event) =>
                                    this.setState({ showVideo: true })
                                  }
                                >
                                  Play Video
                                </Button>
                              )}
                              {this.state.showVideo && (
                                <ReactPlayer
                                  url={`${process.env.REACT_APP_CLIENT_URL}/takeaway/${this.state.currentTakeaway._id}_media${this.state.currentTakeaway.mediaExt}`}
                                  playing
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                        autoPlay: true,
                                        muted: false,
                                      },
                                    },
                                  }}
                                  width="100%"
                                  height="400px"
                                  controls={true}
                                  style={{ overflow: "hidden" }}
                                />
                              )}
                            </Label>
                          </Row>
                        )}
                        {this.state.currentTakeaway.mediaType == "image" && (
                          <Row>
                            <Label sm={3}>Media :</Label>
                            <Label sm={9}>
                              {!this.state.showImage && (
                                <Button
                                  onClick={(event) =>
                                    this.setState({ showImage: true })
                                  }
                                >
                                  View Image
                                </Button>
                              )}
                              {this.state.showImage && (
                                <img
                                  src={`${process.env.REACT_APP_CLIENT_URL}/takeaway/${this.state.currentTakeaway._id}_media${this.state.currentTakeaway.mediaExt}`}
                                  width="500"
                                  height="600"
                                />
                              )}
                            </Label>
                          </Row>
                        )}
                        {this.state.currentTakeaway.mediaType == "audio" && (
                          <Row>
                            <Label sm={3}>Media :</Label>
                            <Label sm={9}>
                              {!this.state.showAudio && (
                                <Button
                                  onClick={(event) =>
                                    this.setState({ showAudio: true })
                                  }
                                >
                                  Play Audio
                                </Button>
                              )}
                              {this.state.showAudio && (
                                <audio controls autoPlay>
                                  <source
                                    src={`${process.env.REACT_APP_CLIENT_URL}/takeaway/${this.state.currentTakeaway._id}_media${this.state.currentTakeaway.mediaExt}`}
                                    type="audio/mpeg"
                                  />
                                </audio>
                              )}
                            </Label>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={this.detailsTakeawayModalToggle}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal
                isOpen={this.state.createTakeawayModal}
                toggle={this.createTakeawayModalToggle}
                className="modal-lg"
              >
                <ModalHeader toggle={this.createTakeawayModalToggle}>
                  Create Takeaway
                </ModalHeader>
                <Form>
                  <ModalBody>
                    <Row>
                      <Col xl={12} lg={12} md={12}>
                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Title
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="title"
                              placeholder="Enter Title"
                              value={this.state.takeaway.title || ""}
                              onChange={(event) => {
                                event.persist();
                                this.handleUserInput(event);
                                this.setState((prevState) => ({
                                  takeaway: {
                                    ...prevState.takeaway,
                                    title: event.target.value,
                                  },
                                }));
                              }}
                            />
                            {!this.state.titleValid ? (
                              <span className="float-right text-danger">
                                <small>{this.state.errors.title}</small>
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Message
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="textarea"
                              name="message"
                              placeholder="Enter Message"
                              value={this.state.takeaway.message}
                              onChange={(event) => {
                                event.persist();
                                this.handleUserInput(event);

                                this.setState((prevState) => ({
                                  takeaway: {
                                    ...prevState.takeaway,
                                    message: event.target.value,
                                  },
                                }));
                              }}
                            />
                            {!this.state.messageValid ? (
                              <span className="float-right text-danger">
                                <small>{this.state.errors.message}</small>
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Cover Photo
                          </Label>
                          <Col sm={10}>
                            <span className="" style={{ cursor: "pointer" }}>
                              <Button onClick={() => this.fileInput.click()}>
                                Upload Cover Photo
                              </Button>
                              <input
                                style={{ display: "none" }}
                                type="file"
                                name="coverPhoto"
                                accept="image/x-png,image/jpeg"
                                placeholder="Cover Photo"
                                onChange={(event) => {
                                  console.log(event.target.files[0]);
                                  event.persist();
                                  this.handleUserInput(event);

                                  this.setState((prevState) => ({
                                    takeaway: {
                                      ...prevState.takeaway,
                                      coverPhoto: event.target.files[0],
                                    },
                                  }));
                                }}
                                ref={(fileInput) =>
                                  (this.fileInput = fileInput)
                                }
                              />
                              {this.state.takeaway &&
                                this.state.takeaway.coverPhoto && (
                                  <span className="ml-1">
                                    {this.state.takeaway.coverPhoto.name}
                                  </span>
                                )}
                            </span>
                            {!this.state.coverPhotoValid ? (
                              <span className="float-right text-danger">
                                <small>{this.state.errors.coverPhoto}</small>
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Media Type
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="select"
                              name="mediaType"
                              value={this.state.takeaway.mediaType}
                              onChange={(event) => {
                                event.persist();
                                this.handleUserInput(event);
                                this.setState((prevState) => ({
                                  takeaway: {
                                    ...prevState.takeaway,
                                    mediaType: event.target.value,
                                    media: "",
                                  },
                                  finalValidate: false,
                                }));
                              }}
                            >
                              <option value="">Select</option>
                              <option value="image">Image</option>
                              <option value="video">Video</option>
                              <option value="audio">Audio</option>
                            </Input>
                            {!this.state.mediaTypeValid ? (
                              <span className="float-right text-danger">
                                <small>{this.state.errors.mediaType}</small>
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Media
                          </Label>
                          <Col sm={10}>
                            <span className="" style={{ cursor: "pointer" }}>
                              <Button
                                disabled={!this.state.mediaTypeValid}
                                onClick={() => this.fileInput1.click()}
                              >
                                Upload Media
                              </Button>
                              <input
                                style={{ display: "none" }}
                                type="file"
                                name="media"
                                accept={this.state.takeaway.mediaType + "/*"}
                                placeholder="Cover Photo"
                                onChange={(event) => {
                                  event.persist();
                                  this.handleUserInput(event);
                                  this.mediaValidation(event.target.files[0]);
                                  this.setState((prevState) => ({
                                    takeaway: {
                                      ...prevState.takeaway,
                                      media: event.target.files[0],
                                    },
                                  }));
                                }}
                                ref={(fileInput1) =>
                                  (this.fileInput1 = fileInput1)
                                }
                              />
                              {this.state.takeaway && this.state.takeaway.media && (
                                <span className="ml-1">
                                  {this.state.takeaway.media.name}
                                </span>
                              )}
                            </span>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Link
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="link"
                              placeholder="Enter Link"
                              value={this.state.takeaways.link}
                              onChange={(event) => {
                                event.persist();
                                this.setState((prevState) => ({
                                  takeaway: {
                                    ...prevState.takeaway,
                                    link: event.target.value,
                                  },
                                }));
                              }}
                            ></Input>
                            {!this.state.linkValid ? (
                              <span className="float-right text-danger">
                                <small>{this.state.errors.link}</small>
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={this.createTakeawayModalToggle}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      disabled={
                        !(this.state.formValid && this.state.finalValidate)
                      }
                      onClick={(event) => this.onCreateTakeawaySubmit(event)}
                      type="submit"
                    >
                      Create
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>
              <Modal
                isOpen={this.state.mintTakeawayModal}
                toggle={this.mintTakeawayModalToggle}
                className="modal-lg"
              >
                <ModalHeader toggle={this.mintTakeawayModalToggle}>
                  Takeaway Mint
                </ModalHeader>
                <Form>
                  <ModalBody>
                    <Row>
                      <Col xl={12} lg={12} md={12}>
                        <FormGroup row>
                          <Label for="exampleEmail" sm={2}>
                            Value:
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="mint"
                              placeholder="Value"
                              value={this.state.mint.mintTotal || ""}
                              onChange={(event) => {
                                event.persist();
                                this.handleMintInput(event);
                                this.setState((prevState) => ({
                                  mint: {
                                    ...prevState.mint,
                                    mintTotal: event.target.value,
                                  },
                                }));
                              }}
                            />
                            {!this.state.mintValid ? (
                              <span className="float-right text-danger">
                                <small>{this.state.errors.mint}</small>
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={this.mintTakeawayModalToggle}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      disabled={!this.state.formMintValid}
                      onClick={(event) => this.onCreateTakeawayMintSubmit(event)}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                {/* <CardHeader>
                  <Row>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex">
                        <div sm={4} className="mt-2 pr-2">
                          <Label>Shown</Label>
                        </div>
                        <div sm={4}>
                          <Input
                            type="select"
                            name="pageLimit"
                            onChange={(event) => {
                              this.refreshPagination(1, event.target.value);
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Input>
                        </div>
                        <div sm={4} className="mt-2 pl-2">
                          <Label>entries</Label>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex justify-content-center">
                        <div className="mt-2 pr-2">
                          <Label>
                            Showing{" "}
                            {!this.state.totalTakeaways
                              ? 0
                              : this.state.page * this.state.limit -
                                (this.state.limit - 1)}{" "}
                            to{" "}
                            {this.state.page * this.state.limit >
                            this.state.totalTakeaways
                              ? this.state.totalTakeaways
                              : this.state.page * this.state.limit}{" "}
                            of {this.state.totalTakeaways}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex justify-content-end">
                        <div sm={4} className="pr-2">
                          <Button
                            className="rounded-circle"
                            disabled={this.state.page <= 1}
                            onClick={(event) =>
                              this.refreshPagination(
                                this.state.page - 1,
                                this.state.limit
                              )
                            }
                          >
                            <MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft>
                          </Button>
                        </div>
                        <div sm={4}>
                          <Input
                            type="select"
                            value={this.state.page}
                            name="filter"
                            onChange={(event) => {
                              this.refreshPagination(
                                event.target.value,
                                this.state.limit
                              );
                            }}
                          >
                            {this.state.pageNumbers.map((pageNumber, index) => (
                              <option value={pageNumber} key={index}>
                                {pageNumber}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div sm={4} className="pl-2">
                          <Button
                            className="rounded-circle"
                            disabled={this.state.page >= this.state.pages}
                            onClick={(event) =>
                              this.refreshPagination(
                                this.state.page + 1,
                                this.state.limit
                              )
                            }
                          >
                            <MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader> */}
                <CardBody>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Link</th>
                        <th>Media Type</th>
                        <th>Total Mint</th>
                        <th>Available Mint</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.takeaways && this.state.takeaways.length > 0 ? (
                        this.state.takeaways.map((takeaway, index) => (
                          <tr key={index}>
                            <td>
                              <Button
                                color="link"
                                style={{ textAlign: "left" }}
                                onClick={(event) => this.showDetails(takeaway)}
                              >
                                {takeaway.title}
                              </Button>
                            </td>
                            <td>{takeaway.message}</td>
                            <td><a href={takeaway.link} target="_blank">{takeaway.link}</a></td>
                            <td>
                              {GeneralFunctions.capitalize(takeaway.mediaType)}
                            </td>
                            <td>{takeaway.mintTotal}</td>
                            <td>{takeaway.mintAvailable}</td>
                            <td>
                              <Button
                                disabled={takeaway.mintTotal ? true : false}
                                onClick={(e) => {
                                  this.mintTakeaway();
                                  this.setState({ mintId: takeaway._id });
                                }}
                                color="primary"
                              >
                                Mint
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                          <tr>
                            <td colSpan="12" className="text-center">
                              No takeaways found.
                          </td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <NotificationSystem
            dismissible={false}
            ref={(notificationSystem) =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </Page>
      </div>
    );
  }
}
export default Takeaways;
