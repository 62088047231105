import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Badge, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDeleteForever, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import * as Server from '../Server';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';

let selectedWorkoutClassId = null;
class WorkoutClasses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            collapseFilter: false,
            removeWorkoutConfirmationModal: false,
            workoutDetailModel: false,
            workoutStatusId: null,
            workoutClasses: [],
            allCategories: [],
            categories: [],
            subCategories: [],
            filter: {
                level: "",
                category: "",
                subCategory: "",
                status: "",
                title: "",
            },
            page: 1,
            limit: 10,
            totalWorkoutClasses: 0,
            pages: 0,
            pageNumbers: [],
            workoutClassDetail: {}
        };
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('workoutClasses')) this.props.history.push('/dashboard');
        this.getCategories();
        this.getWorkoutClassDetails();
    }

    getCategories = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: '/categories',
                method: 'GET',
            });
            if (response) {
                let categories = response.categories.find(obj => obj._id == 'workoutclasses').cats;
                this.setState({
                    showLoader: false,
                    allCategories: response.categories,
                    categories: categories
                });
            }
        }
        catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    getWorkoutClassDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let filter = this.state.filter;
            let page = this.state.page;
            let limit = this.state.limit;
            let response = await Server.request({
                url: 'admin/workoutClass/search',
                method: 'POST',
                data: { filter, page, limit }
            });
            this.setState({ showLoader: false });
            this.setState({ workoutClasses: response.workoutClasses, totalWorkoutClasses: response.totalWorkoutClasses, pages: response.pages });
            let pageNumbers = [];
            for (let page = 1; page <= response.pages; page++) {
                pageNumbers.push(page);
            }
            this.setState({ pageNumbers: pageNumbers });
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    removeWorkoutClass = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/workoutClass/' + id + '/removeWorkoutClass',
                method: 'DELETE'
            });
            if (response) {
                this.setState({ showLoader: false });
                selectedWorkoutClassId = null;
                this.notificationSystem.addNotification({
                    message: 'Workout Class removed successfully.',
                    level: 'success',
                });
                this.setState({ removeWorkoutConfirmationModal: false });
                this.getWorkoutClassDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    toggleRemoveWorkoutConfirmationModal = async (id) => {
        if (!selectedWorkoutClassId && id) {
            selectedWorkoutClassId = id;
        }
        this.setState({
            removeWorkoutConfirmationModal: !this.state.removeWorkoutConfirmationModal
        });
    }

    setWorkoutId = async (_id) => {
        this.setState({ workoutStatusId: _id });
    }

    changeStatus = async (workoutClassId, value) => {
        try {
            this.setState({ showLoader: true });
            let status = value;
            let response = await Server.request({
                url: 'admin/workoutClass/updateStatus',
                method: 'POST',
                data: { workoutClassId, status }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: 'Workout Class status updated successfully.',
                    level: 'success',
                });
                this.setState({ workoutStatusId: null });
                this.getWorkoutClassDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    search() {
        this.setState({ page: parseInt(1, 10) }, () => {
            this.getWorkoutClassDetails();
        });
    }

    refreshPagination = async (page, limit) => {
        this.setState({ page: parseInt(page, 10), limit: limit }, () => {
            this.getWorkoutClassDetails();
        });
    }

    getWorkoutClassInfo = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/workoutClass/details/' + id,
                method: 'GET'
            });
            if (response) {
                this.setState({ workoutClassDetail: response.workoutClass });
                this.setState({ workoutDetailModel: true });
                this.setState({ showLoader: false });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    closeModal = async () => {
        this.setState({ workoutDetailModel: false })
    }

    getSubCategory = (categoryId) => {
        if (categoryId) {
            let category = this.state.categories.find(obj => obj._id === categoryId);
            this.setState({ subCategories: category.subCats });
        }
        else {
            this.setState({ subCategories: [] });
        }
    }

    render() {
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Live Workouts"
                    breadcrumbs={[{ name: 'Live Workouts', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>
                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.search() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="title" sm={3}>
                                                            Title
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="text"
                                                                name="title"
                                                                placeholder="Title"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            title: event.target.value
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="level" sm={3}>
                                                            Level
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="level" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        level: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="beginner">Beginner</option>
                                                                <option value="intermediate">Intermediate</option>
                                                                <option value="advanced">Advanced</option>
                                                                <option value="everyone">Everyone</option>
                                                                <option value="All">All</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="category" sm={3}>
                                                            Category
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="select"
                                                                name="category"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            category: event.target.value
                                                                        }
                                                                    }), () => {
                                                                        if (this.state.filter.category === '') {
                                                                            this.setState({
                                                                                filter: {
                                                                                    ...this.state.filter,
                                                                                    subCategory: ''
                                                                                }
                                                                            })
                                                                        }
                                                                    });
                                                                    this.getSubCategory(event.target.value);
                                                                }}>
                                                                <option value="">Select</option>
                                                                {this.state.allCategories && this.state.categories.map((category) => {
                                                                    return (<option value={category._id} key={category._id}>{category.name}</option>)
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup></Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="subCategories" sm={3}>
                                                            Sub-Category
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="select"
                                                                name="subCategories"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            subCategory: event.target.value
                                                                        }
                                                                    }));
                                                                }
                                                                }>
                                                                <option value="">Select</option>
                                                                {this.state.subCategories.map((subcategory) => {
                                                                    return (<option value={subcategory._id} key={subcategory._id}>{subcategory.name}</option>)
                                                                })}
                                                            </Input>

                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="status" sm={3}>
                                                            Status
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="status" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        status: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="approved">Approved</option>
                                                                <option value="rejected">Rejected</option>
                                                                <option value="completed">Completed</option>
                                                                <option value="cancelled">Cancelled</option>
                                                                <option value="live">Live</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardHeader>
                                    <Row>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex">
                                                <div sm={4} className="mt-2 pr-2"><Label>Shown</Label></div>
                                                <div sm={4}>
                                                    <Input type="select" name="pageLimit" onChange={(event) => { this.refreshPagination(1, event.target.value) }}>
                                                        <option value={10}>10</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Input>
                                                </div>
                                                <div sm={4} className="mt-2 pl-2"><Label>entries</Label></div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-2 pr-2">
                                                    <Label>Showing {!this.state.totalWorkoutClasses ? 0 : ((this.state.page * this.state.limit) - (this.state.limit - 1))}  to {(this.state.page * this.state.limit) > this.state.totalWorkoutClasses ? this.state.totalWorkoutClasses : (this.state.page * this.state.limit)} of {this.state.totalWorkoutClasses}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-end">
                                                <div sm={4} className="pr-2"><Button className="rounded-circle" disabled={this.state.page <= 1} onClick={(event) => this.refreshPagination(this.state.page - 1, this.state.limit)}><MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft></Button></div>
                                                <div sm={4}>
                                                    <Input type="select" value={this.state.page} name="filter" onChange={(event) => { this.refreshPagination(event.target.value, this.state.limit) }}>
                                                        {
                                                            this.state.pageNumbers.map((pageNumber, index) => (
                                                                <option value={pageNumber} key={index}>{pageNumber}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                                <div sm={4} className="pl-2"><Button className="rounded-circle" disabled={this.state.page >= this.state.pages} onClick={(event) => this.refreshPagination(this.state.page + 1, this.state.limit)}><MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight></Button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Level</th>
                                                <th>Category</th>
                                                <th>SubCategory</th>
                                                <th>Posted By</th>
                                                <th>Date & Time</th>
                                                <th>Duration</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.workoutClasses && this.state.workoutClasses.length > 0
                                                    ? this.state.workoutClasses.map((workoutClass, index) => (
                                                        <tr key={index}>
                                                            <td><Button color="link" style={{ textAlign: 'left' }} onClick={(event) => this.getWorkoutClassInfo(workoutClass._id)}>{workoutClass.title}</Button></td>
                                                            <td>{GeneralFunctions.capitalize(workoutClass.level)}</td>
                                                            <td>{workoutClass.category}</td>
                                                            <td>{workoutClass.subCategory}</td>
                                                            <td>{workoutClass.owner.firstName} {workoutClass.owner.middleName || ''} {workoutClass.owner.lastName || ''}</td>
                                                            <td>{<Moment format="llll">{workoutClass.scheduledAt}</Moment>}</td>
                                                            <td>{workoutClass.duration && workoutClass.duration + ' min'}</td>
                                                            {
                                                                this.state.workoutStatusId === workoutClass._id ?
                                                                    <td>
                                                                        <Input type="select" name="status" value={workoutClass.status} onChange={(event) => { this.changeStatus(workoutClass._id, event.target.value) }} >
                                                                            <option value="">Select</option>
                                                                            <option value="approved">Approve</option>
                                                                            <option value="rejected">Reject</option>
                                                                        </Input>
                                                                    </td>
                                                                    : <td>
                                                                        {
                                                                            workoutClass.status == 'pending' &&
                                                                            <Badge color="warning" pill>{GeneralFunctions.capitalize(workoutClass.status)}</Badge>
                                                                        }
                                                                        {
                                                                            (workoutClass.status == 'approved' || workoutClass.status == 'live' || workoutClass.status == 'completed') &&
                                                                            <Badge color="success" pill>{GeneralFunctions.capitalize(workoutClass.status)}</Badge>
                                                                        }
                                                                        {
                                                                            (workoutClass.status == 'cancelled' || workoutClass.status == 'rejected') &&
                                                                            <Badge color="danger" pill>{GeneralFunctions.capitalize(workoutClass.status)}</Badge>
                                                                        }
                                                                        {
                                                                            workoutClass.status != 'completed' &&
                                                                            <Button color="link" onClick={() => this.setWorkoutId(workoutClass._id)}>Change</Button>
                                                                        }
                                                                    </td>
                                                            }
                                                            <td><Button onClick={(event) => this.toggleRemoveWorkoutConfirmationModal(workoutClass._id)}><MdDeleteForever size="20px"></MdDeleteForever></Button></td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="10" className="text-center">No workout classes found.</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    this.state.removeWorkoutConfirmationModal ?
                                        <Modal
                                            isOpen={this.state.removeWorkoutConfirmationModal}
                                            toggle={(event) => this.toggleRemoveWorkoutConfirmationModal()}
                                            className="modal-md">
                                            <ModalHeader toggle={(event) => this.toggleRemoveWorkoutConfirmationModal()}></ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to delete this workout class ?
                                        </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.toggleRemoveWorkoutConfirmationModal()}>Cancel</Button>{' '}
                                                <Button color="primary" onClick={(event) => { event.preventDefault(); this.toggleRemoveWorkoutConfirmationModal(); this.removeWorkoutClass(selectedWorkoutClassId) }}>
                                                    Delete
                                            </Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                                {
                                    this.state.workoutDetailModel ?
                                        <Modal
                                            isOpen={this.state.workoutDetailModel}
                                            toggle={(event) => this.closeModal()}
                                            className="modal-lg">
                                            <ModalHeader toggle={(event) => this.closeModal()}>Live Workout Details</ModalHeader>
                                            <ModalBody>
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Row>
                                                            <Label sm={3}>Title :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.title)}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Level :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.level)}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Category :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.category)}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Sub Category :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.subCategory)}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Posted By :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.owner.firstName)} {this.state.workoutClassDetail.owner.middleName || ''} {this.state.workoutClassDetail.owner.lastName || ''}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Description:</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.description)}</Label>
                                                        </Row>

                                                        <Row>
                                                            <Label sm={3}>Date & Time :</Label>
                                                            <Label sm={9}>{<Moment format="llll">{this.state.workoutClassDetail.scheduledAt}</Moment>}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Duration :</Label>
                                                            <Label sm={9}>{this.state.workoutClassDetail.duration && this.state.workoutClassDetail.duration + ' min'}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Status :</Label>
                                                            <Label sm={9}>
                                                                {
                                                                    this.state.workoutClassDetail.status == 'pending' &&
                                                                    <Badge color="warning" pill>{GeneralFunctions.capitalize(this.state.workoutClassDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.workoutClassDetail.status == 'live' &&
                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.workoutClassDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.workoutClassDetail.status == 'approved' &&
                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.workoutClassDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.workoutClassDetail.status == 'completed' &&
                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.workoutClassDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.workoutClassDetail.status == 'cancelled' &&
                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(this.state.workoutClassDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.workoutClassDetail.status == 'rejected' &&
                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(this.state.workoutClassDetail.status)}</Badge>
                                                                }
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Cancel Message  :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.workoutClassDetail.cancelMessage) || ''}</Label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.closeModal()}>Cancel</Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div >
        );
    }
}
export default WorkoutClasses;
