import React from "react";
import {
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Page from "../components/Page";

import {
  MdFilterList,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import * as Server from "../Server";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import * as GeneralFunctions from "../GeneralFunctions";
import PageSpinner from "../components/PageSpinner";
import Moment from "react-moment";

class ServiceRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      collapseFilter: false,
      serviceRequests: [],
      filter: {
        email: "",
      },
      page: 1,
      limit: 10,
      total: 0,
      pages: 0,
      pageNumbers: [],
      requestInfoModal: false,
      selectedRequest: null,
    };
  }

  componentDidMount() {
    let permissions = GeneralFunctions.getUserPermission();
    const id =
      this.props.match && this.props.match.params
        ? this.props.match.params.id
        : null;
    if (!permissions.includes("mpkUsers") || !id)
      this.props.history.push("/dashboard");
    this.getServiceRequestsByUser();
  }

  getServiceRequestsByUser = async () => {
    try {
      this.setState({ showLoader: true });
      const filters = this.state.filter;
      const page = this.state.page;
      const limit = this.state.limit;
      const { id, serviceId } = this.props.match.params;
      const response = await Server.request({
        url: `admin/user/mpk/serviceRequest/${id}/byService/${serviceId}`,
        method: "POST",
        data: { filters, page, limit },
      });
      this.setState({ showLoader: false });
      this.setState({
        serviceRequests: response.results,
        total: response.total,
        pages: response.pages,
      });
      let pageNumbers = [];
      for (let page = 1; page <= response.pages; page++) {
        pageNumbers.push(page);
      }
      this.setState({ pageNumbers: pageNumbers });
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  search() {
    this.setState({ page: parseInt(1, 10) }, () => {
      this.getServiceRequestsByUser();
    });
  }

  refreshPagination = async (page, limit) => {
    this.setState({ page: parseInt(page, 10), limit: limit }, () => {
      this.getServiceRequestsByUser();
    });
  };

  viewRequestInfo = (request) => {
    this.setState({ selectedRequest: request, requestInfoModal: true });
  };

  closeRequestModal = () => {
    this.setState({
      requestInfoModal: false,
      selectedRequest: null,
    });
  };

  render() {
    return (
      <div>
        <PageSpinner showLoader={this.state.showLoader} />
        <Page
          title="Service Requests"
          breadcrumbs={[
            { name: "MPK Users", active: true, link: "/mpkUsers" },
            {
              name: "Services",
              active: true,
              link: `/mpkUsers/services/${this.props.match.params.id}`,
            },
            { name: "Service Requests", active: true },
          ]}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader
                  onClick={(event) => {
                    this.setState({
                      collapseFilter: !this.state.collapseFilter,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <React.Fragment>
                    <MdFilterList
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></MdFilterList>
                    Filter
                    {this.state.collapseFilter ? (
                      <MdKeyboardArrowUp
                        style={{ fontSize: 14, float: "right" }}
                      ></MdKeyboardArrowUp>
                    ) : (
                      <MdKeyboardArrowDown
                        style={{ fontSize: 14, float: "right" }}
                      ></MdKeyboardArrowDown>
                    )}
                  </React.Fragment>
                </CardHeader>
                <Collapse isOpen={this.state.collapseFilter}>
                  <CardBody>
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.search();
                      }}
                    >
                      <Row>
                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleEmail" sm={2}>
                              Email
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                className="has-input"
                                placeholder="Email ID"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      email: event.target.value,
                                    },
                                  }));
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <FormGroup className="d-flex justify-content-center w-100">
                          <Button style={{ width: "30%" }} type="submit">
                            Search
                          </Button>
                        </FormGroup>
                      </Row>
                    </Form>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                <CardHeader>
                  <Row>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex">
                        <div sm={4} className="mt-2 pr-2">
                          <Label>Shown</Label>
                        </div>
                        <div sm={4}>
                          <Input
                            type="select"
                            name="pageLimit"
                            onChange={(event) => {
                              this.refreshPagination(1, event.target.value);
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Input>
                        </div>
                        <div sm={4} className="mt-2 pl-2">
                          <Label>entries</Label>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex justify-content-center">
                        <div className="mt-2 pr-2">
                          <Label>
                            Showing{" "}
                            {!this.state.total
                              ? 0
                              : this.state.page * this.state.limit -
                                (this.state.limit - 1)}{" "}
                            to{" "}
                            {this.state.page * this.state.limit >
                            this.state.total
                              ? this.state.total
                              : this.state.page * this.state.limit}{" "}
                            of {this.state.total}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex justify-content-end">
                        <div sm={4} className="pr-2">
                          <Button
                            className="rounded-circle"
                            disabled={this.state.page <= 1}
                            onClick={(event) =>
                              this.refreshPagination(
                                this.state.page - 1,
                                this.state.limit
                              )
                            }
                          >
                            <MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft>
                          </Button>
                        </div>
                        <div sm={4}>
                          <Input
                            type="select"
                            value={this.state.page}
                            name="filter"
                            onChange={(event) => {
                              this.refreshPagination(
                                event.target.value,
                                this.state.limit
                              );
                            }}
                          >
                            {this.state.pageNumbers.map((pageNumber, index) => (
                              <option value={pageNumber} key={index}>
                                {pageNumber}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div sm={4} className="pl-2">
                          <Button
                            className="rounded-circle"
                            disabled={this.state.page >= this.state.pages}
                            onClick={(event) =>
                              this.refreshPagination(
                                this.state.page + 1,
                                this.state.limit
                              )
                            }
                          >
                            <MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Email</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.serviceRequests &&
                      this.state.serviceRequests.length > 0 ? (
                        this.state.serviceRequests.map((sr, index) => (
                          <tr key={index}>
                            <td>
                              <Button
                                color="link"
                                style={{
                                  textAlign: "left",
                                  padding: 0,
                                }}
                                onClick={() => this.viewRequestInfo(sr)}
                              >
                                {sr.service.title}
                              </Button>
                            </td>
                            <td>{sr.email}</td>
                            <td>
                              {
                                <Moment format="MM/DD/YYYY">
                                  {sr.createdAt}
                                </Moment>
                              }
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No service requests found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                {this.state.requestInfoModal && (
                  <Modal
                    isOpen={this.state.requestInfoModal}
                    toggle={this.closeRequestModal}
                    className="modal-lg"
                  >
                    <ModalHeader toggle={this.closeRequestModal}>
                      Service Request Details
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <Row>
                            <Label sm={4}>Service :</Label>
                            <Label sm={8}>
                              {this.state.selectedRequest.service.title}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Email :</Label>
                            <Label sm={8}>
                              {this.state.selectedRequest.email}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Date :</Label>
                            <Label sm={8}>
                              {
                                <Moment format="MM/DD/YYYY">
                                  {this.state.selectedRequest.createdAt}
                                </Moment>
                              }
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Message :</Label>
                            <Label sm={8} className="wrap-long-text">
                              {this.state.selectedRequest.message || "-"}
                            </Label>
                          </Row>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={this.closeRequestModal}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}
              </Card>
            </Col>
          </Row>
          <NotificationSystem
            dismissible={false}
            ref={(notificationSystem) =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </Page>
      </div>
    );
  }
}
export default ServiceRequests;
