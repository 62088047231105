import Avatar from '../Avatar';
import { UserCard } from '../Card';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdPerson
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Spinner
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import bn from '../../utils/bemnames';
import * as GeneralFunctions from '../../GeneralFunctions';
import * as Server from '../../Server';
import userImage from '../../assets/img/user.jpg';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

const bem = bn.create('header');

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
      isOpenUserCardPopover: false,
      isLoggedOut: false
    };
    this.authUser = null;
  }

  componentDidMount() {
  }

  logout = async () => {
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: 'user/logout',
        method: 'POST',
        data: {}
      });
      if (response) {
        this.setState({ showLoader: false });
        GeneralFunctions.clearLocalStorage(false);
        this.setState({ isLoggedOut: true });
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: 'error',
      });
    }
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    if (this.state.isLoggedOut) {
      return (<Redirect to={'/login'} />);
    }
    let profile = GeneralFunctions.getAuthUser();
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <span style={{ marginRight: 5 }}>Welcome {GeneralFunctions.stringTruncate(profile.firstName + ' ' + profile.lastName, 20)}</span>
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
                src={profile.profilePic ? profile.profilePic : userImage}
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0">
                <UserCard
                  title={GeneralFunctions.stringTruncate(profile.firstName + ' ' + profile.lastName, 20)}
                  subtitle={GeneralFunctions.stringTruncate(profile.email, 22)}
                  avatar={profile.profilePic ? profile.profilePic : userImage}
                >
                  <ListGroup flush>
                    <ListGroupItem action tag="a" href="/profile">
                      <MdPerson /> My Profile
                      </ListGroupItem>
                    {
                      this.state.showLoader ?
                        <ListGroupItem tag="button" action>
                          <Spinner size="sm" color='primary' />
                        </ListGroupItem>
                        :
                        <ListGroupItem tag="button" action onClick={() => this.logout()}>
                          <MdExitToApp /> Signout
                    </ListGroupItem>
                    }
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </Navbar>
    );
  }
}

export default Header;
