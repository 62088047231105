import React from 'react';
import { Spinner } from 'reactstrap';

class PageSpinner extends React.Component {

  render() {
    const { showLoader } = this.props;
    return (
      showLoader ? 
      <div className="d-flex justify-content-center loader-overlay loading" style={{zIndex:2100}}>
        <Spinner color='primary' />
      </div>
      : null
    );
  }
}

export default PageSpinner;
