import React from 'react';
import {
    Col, Row,
    Card, CardBody, CardHeader, Table, Button, Form, FormGroup, Input, Label
} from 'reactstrap';
import { MdModeEdit } from 'react-icons/md';
import Page from '../components/Page';
import PageSpinner from '../components/PageSpinner';
import * as Server from '../Server';
import * as GeneralFunctions from '../GeneralFunctions';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            user: {},
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPasswordValidater: '',
            newPasswordValidater: '',
            confirmPassowrdValidater: ''
        };
    }

    componentDidMount() {
        let user = GeneralFunctions.getAuthUser();
        this.setState({ user: user });
    }

    async updateProfile() {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/updateProfile',
                method: 'POST',
                data: {
                    firstName: this.state.user.firstName,
                    lastName: this.state.user.lastName,
                    mobileNumber: this.state.user.mobileNumber,
                    profilePic: this.state.user.profilePic
                }
            });
            if (response) {
                this.setState({ showLoader: false });
                GeneralFunctions.setAuthUser(response.user);
                let user = GeneralFunctions.getAuthUser();
                this.setState({ user: user });
                this.notificationSystem.addNotification({
                    message: 'Profile updated successfully.',
                    level: 'success',
                });
                this.props.history.push('/profile');
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }

    }

    async changePassword() {
        try {
            if (this.state.newPassword != this.state.confirmPassword) {
                this.notificationSystem.addNotification({
                    message: 'Confirm password not matched.',
                    level: 'error',
                });
                this.setState({ oldPassword: '', newPassword: '', confirmPassword: '' });
                return;
            }
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/changePassword',
                method: 'POST',
                data: { oldPassword: this.state.oldPassword, newPassword: this.state.newPassword }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.setState({ oldPassword: '', newPassword: '', confirmPassword: '' });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
            this.setState({ oldPassword: '', newPassword: '', confirmPassword: '' });
        }

    }

    uploadProfilePic = async (obj) => {
        const formData = await new FormData();
        await formData.append('media', obj.media);
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: '/admin/uploadProfilePic',
                method: 'POST',
                data: formData || {}
            });
            this.setState({ showLoader: false });
            GeneralFunctions.setAuthUser(response.user);
            let user = GeneralFunctions.getAuthUser();
            this.setState({ user: user });
            this.notificationSystem.addNotification({
                message: 'Profile pic updated successfully.',
                level: 'success',
            });
            this.props.history.push('/profile');
            return response;
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    fileSelectedHandler = async (media) => {
        if (media.type != 'image/jpeg' && media.type != 'image/jpg' && media.type != 'image/png') {
            this.notificationSystem.addNotification({
                message: "Please select only valid JPEG or PNG image format.",
                level: 'error',
            });
            return;
        }
        if (media.size > 5242880) {
            this.notificationSystem.addNotification({
                message: "Image size should be less than 5MB.",
                level: 'error',
            });
            return;
        }
        await this.uploadProfilePic({ media });
    }

    getFileResult = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    onChange(key, value) {
        let obj = {
            [key]: value
        };
        if (key && value) {
            if (key === 'oldPassword') {
                this.oldPassword = true;
            }
            if (key === 'newPassword') {
                this.newPassword = true;
            }
            if (key === 'confirmPassword') {
                this.confirmPassword = true;
            }
        }

        if (key === 'oldPassword') {
            if (obj.oldPassword.length === 0) {
                this.setState({
                    oldPasswordValidater: '*Required'
                });
            };
        }
        if (key === 'newPassword') {
            if (obj.newPassword.length === 0) {
                this.setState({ newPasswordValidater: '*Required' })
            };
        }
        if (key === 'confirmPassword') {
            if (obj.confirmPassword.length === 0) {
                this.setState({ confirmPassowrdValidater: "*Required" });
            }
        }
    }

    render() {
        let role = GeneralFunctions.getUserRole();
        const disabled = this.state.oldPassword.length && this.state.newPassword.length && this.state.confirmPassword.length;
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="My Profile"
                    breadcrumbs={[{ name: 'My Profile', active: true }]}
                >
                    <Row>
                        <Col xl={6} lg={6} md={6}>
                            <Card>
                                <CardHeader>Profile</CardHeader>
                                <CardBody>
                                    <Form onSubmit={(event) => { event.preventDefault(); this.updateProfile() }}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={3}>
                                                        Profile Pic
                                                    </Label>
                                                    <Col sm={9}>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="image_outer_container">
                                                                <input style={{ display: 'none' }} type="file" accept="image/*" onChange={(event) => this.fileSelectedHandler(event.target.files[0])}
                                                                    onClick={(event) => { event.target.value = null }} ref={fileInput => this.fileInput = fileInput} />
                                                                <div className="image_inner_container">
                                                                    <img src={this.state.user.profilePic ? this.state.user.profilePic : require('../assets/img/user.jpg')} alt="Profile" className="rounded-circle" height="100" width="100" />
                                                                    <span className="home-profile-image-edit" style={{ cursor: 'pointer' }} onClick={() => this.fileInput.click()} >
                                                                        <MdModeEdit style={{ fontSize: 16 }} />
                                                                        <input style={{ display: 'none' }} type="file" accept="image/*" onChange={(event) => this.fileSelectedHandler(event.target.files[0])} ref={fileInput => this.fileInput = fileInput} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={3}>
                                                        First Name
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            id="firstName"
                                                            className="has-input"
                                                            placeholder="First Name"
                                                            value={this.state.user.firstName}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    user: {
                                                                        ...prevState.user,
                                                                        firstName: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {!this.state.user.firstName ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={3}>
                                                        Last Name
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            id="lastName"
                                                            className="has-input"
                                                            placeholder="Last Name"
                                                            value={this.state.user.lastName}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    user: {
                                                                        ...prevState.user,
                                                                        lastName: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {!this.state.user.lastName ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={3}>
                                                        Email Id
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="text"
                                                            name="emailId"
                                                            id="emailId"
                                                            className="has-input"
                                                            placeholder="Email Id"
                                                            disabled
                                                            value={this.state.user.email}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={3}>
                                                        Phone No
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            className="has-input"
                                                            placeholder="Phone No"
                                                            value={this.state.user.mobileNumber}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    user: {
                                                                        ...prevState.user,
                                                                        mobileNumber: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={3}>
                                                        Role
                                                    </Label>
                                                    <Label for="exampleEmail" sm={9}>
                                                        {role.name}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className="d-flex justify-content-center w-100">
                                            <Button style={{ "width": "30%" }} type="submit">Update Profile</Button>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6} lg={6} md={6}>
                            <Card>
                                <CardHeader>Change Password</CardHeader>
                                <CardBody>
                                    <Form onSubmit={(event) => { event.preventDefault(); this.changePassword() }}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={4}>
                                                        Old Password
                                                    </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="password"
                                                            name="oldPassword"
                                                            id="oldPassword"
                                                            className="has-input"
                                                            placeholder="Old Password"
                                                            value={this.state.oldPassword}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("oldPassword", event.target.value);
                                                                this.setState({ oldPassword: event.target.value });
                                                            }}
                                                        />
                                                        {this.state.oldPasswordValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={4}>
                                                        New Password
                                                    </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="password"
                                                            name="newPassword"
                                                            id="newPassword"
                                                            className="has-input"
                                                            placeholder="New Password"
                                                            value={this.state.newPassword}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("newPassword", event.target.value);
                                                                this.setState({ newPassword: event.target.value });
                                                            }}
                                                        />
                                                        {this.state.newPasswordValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={4}>
                                                        Confirm Password
                                                    </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="password"
                                                            name="confirmPassword"
                                                            id="confirmPassword"
                                                            className="has-input"
                                                            placeholder="Confirm Password"
                                                            value={this.state.confirmPassword}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("confirmPassword", event.target.value);
                                                                this.setState({ confirmPassword: event.target.value });
                                                            }}
                                                        />
                                                        {this.state.confirmPassowrdValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className="d-flex justify-content-center w-100">
                                            <Button style={{ "width": "40%" }} type="submit" disabled={!disabled}>Change Password</Button>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div>
        );
    }
}
export default Profile;
