import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Badge, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDeleteForever, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import * as Server from '../Server';
import NotificationSystem from 'react-notification-system';
import { FiExternalLink } from 'react-icons/fi';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

let selectedPostId = null;
let selectedCommentId = null;
let selectedRatingId = null;
let selectedReplyId = null;

class Posts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            collapseFilter: false,
            showVideo: false,
            removePostConfirmationModal: false,
            removeCommentConfirmationModal: false,
            removeRatingConfirmationModal: false,
            removeReplyConfirmationModal: false,
            postDetailModal: false,
            postStatusId: null,
            posts: [],
            allCategories: [],
            categories: [],
            subCategories: [],
            filter: {
                type: "",
                level: "",
                category: "",
                subCategory: "",
                status: "",
                title: "",
            },
            page: 1,
            limit: 10,
            totalPosts: 0,
            pages: 0,
            pageNumbers: [],
            postDetail: {},
            comment: [],
            repliesModel: false
        };
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('posts')) this.props.history.push('/dashboard');
        this.getCategories();
        this.getPostsDetails();
    }

    getCategories = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: '/categories',
                method: 'GET',
            });
            if (response) {
                this.setState({
                    showLoader: false,
                    allCategories: response.categories
                });
            }
        }
        catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    getPostsDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let filter = this.state.filter;
            let page = this.state.page;
            let limit = this.state.limit;
            let response = await Server.request({
                url: 'admin/post/search',
                method: 'POST',
                data: { filter, page, limit }
            });
            this.setState({ showLoader: false });
            this.setState({ posts: response.posts, totalPosts: response.totalPosts, pages: response.pages });
            let pageNumbers = [];
            for (let page = 1; page <= response.pages; page++) {
                pageNumbers.push(page);
            }
            this.setState({ pageNumbers: pageNumbers });
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    removePost = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/post/' + id + '/removePost',
                method: 'DELETE'
            });
            if (response) {
                this.setState({ showLoader: false });
                selectedPostId = null;
                this.notificationSystem.addNotification({
                    message: 'Post removed successfully.',
                    level: 'success',
                });
                this.setState({ removePostConfirmationModal: false });
                this.getPostsDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    toggleRemovePostConfirmationModal = async (id) => {
        if (!selectedPostId && id) {
            selectedPostId = id;
        }
        this.setState({
            removePostConfirmationModal: !this.state.removePostConfirmationModal
        });
    }

    toggleRemoveCommentConfirmationModal = async (postId, commentId) => {

        if (!selectedPostId && !selectedCommentId && commentId) {
            selectedPostId = postId;
            selectedCommentId = commentId
        }

        this.setState({
            removeCommentConfirmationModal: !this.state.removeCommentConfirmationModal
        });
    }

    toggleRemoveRatingConfirmationModal = async (postId, ratingId) => {

        if (!selectedPostId && !selectedRatingId && ratingId) {
            selectedPostId = postId;
            selectedRatingId = ratingId
        }

        this.setState({
            removeRatingConfirmationModal: !this.state.removeRatingConfirmationModal
        });
    }

    toggleRemoveReplyConfirmationModal = async (postId, commentId, replyId) => {

        if (!selectedPostId && !selectedCommentId && !selectedReplyId, replyId) {
            selectedPostId = postId;
            selectedCommentId = commentId;
            selectedReplyId = replyId;
        }
        this.setState({
            removeReplyConfirmationModal: !this.state.removeReplyConfirmationModal
        });
    }


    setPostId = async (_id) => {
        this.setState({ postStatusId: _id });
    }

    changeStatus = async (postId, value) => {
        try {
            this.setState({ showLoader: true });
            let status = value;
            let response = await Server.request({
                url: 'admin/post/updateStatus',
                method: 'POST',
                data: { postId, status }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: 'Post status updated successfully.',
                    level: 'success',
                });
                this.setState({ postStatusId: null });
                this.getPostsDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    search() {
        this.setState({ page: parseInt(1, 10) }, () => {
            this.getPostsDetails();
        });
    }

    refreshPagination = async (page, limit) => {
        this.setState({ page: parseInt(page, 10), limit: limit }, () => {
            this.getPostsDetails();
        });
    }

    getPostInfo = async (id) => {
        try {
            this.setState({ showVideo: false });
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/post/details/' + id,
                method: 'GET'
            });
            if (response) {
                if (selectedCommentId) {
                    let comment = response.post.comments.find(obj => obj._id === selectedCommentId)
                    this.setState({ comment: comment })
                    selectedCommentId = null;
                }
                this.setState({ postDetail: response.post });
                this.setState({ postDetailModal: true });
                this.setState({ showLoader: false });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    closeModal = async () => {
        this.setState({ postDetailModal: false })
    }

    getSubCategory = (categoryId) => {
        if (categoryId) {
            let category = this.state.categories.find(obj => obj._id === categoryId);
            this.setState({ subCategories: category.subCats });
        }
        else {
            this.setState({ subCategories: [] });
        }
    }

    removeComment = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/post/' + selectedPostId + "/" + selectedCommentId + '/removeComment',
                method: 'DELETE'
            });
            if (response) {

                this.setState({ showLoader: false });
                selectedCommentId = null;
                selectedPostId = null;
                this.notificationSystem.addNotification({
                    message: 'Comment removed successfully.',
                    level: 'success',
                });
                this.getPostInfo(this.state.postDetail._id);
            }
        } catch (error) {
            this.setState({ showLoader: false, ids: {} });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    removeRating = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/post/' + selectedPostId + "/" + selectedRatingId + '/removeRating',
                method: 'DELETE'
            });
            if (response) {

                this.setState({ showLoader: false });
                selectedRatingId = null;
                selectedPostId = null;
                this.notificationSystem.addNotification({
                    message: 'Rating removed successfully.',
                    level: 'success',
                });
                this.getPostInfo(this.state.postDetail._id);
            }
        } catch (error) {
            this.setState({ showLoader: false, ids: {} });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    removeReply = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: `admin/post/${selectedPostId}/${selectedCommentId}/${selectedReplyId}/removeReply`,
                method: 'DELETE'
            });
            if (response) {

                this.setState({ showLoader: false });
                selectedReplyId = null;
                selectedPostId = null;
                this.notificationSystem.addNotification({
                    message: 'Reply removed successfully.',
                    level: 'success',
                });
                this.getPostInfo(this.state.postDetail._id);
            }
        } catch (error) {
            this.setState({ showLoader: false, ids: {} });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    handleReplies = (comment) => {
        this.setState({ comment: comment })
        this.toggleRepliesModal()
    }

    toggleRepliesModal = () => {
        this.setState({ repliesModel: !this.state.repliesModel })
    }

    cheackReactionCount = (reactions, reaction) => {
        let value = reactions.filter(obj => obj.reaction == reaction)
        return value.length
    }

    render() {
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Videos"
                    breadcrumbs={[{ name: 'Videos', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>
                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.search() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="title" sm={3}>
                                                            Title
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="text"
                                                                name="title"
                                                                placeholder="Title"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            title: event.target.value
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>

                                                    <FormGroup row>
                                                        <Label for="type" sm={3}>
                                                            Type
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="type" onChange={(event) => {
                                                                event.persist();
                                                                let categories = [];
                                                                if (event.target.value) {
                                                                    categories = this.state.allCategories.find(obj => obj._id == event.target.value).cats;
                                                                }
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        type: event.target.value
                                                                    },
                                                                    subCategories: [],
                                                                    categories: categories
                                                                }), () => {
                                                                    if (this.state.filter.type === '') {
                                                                        this.setState({
                                                                            filter: {
                                                                                ...this.state.filter,
                                                                                level: "",
                                                                                category: "",
                                                                                subCategory: "",
                                                                            }
                                                                        })
                                                                    }
                                                                });
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="quickpeeks">Quick Peeks</option>
                                                                <option value="deepdives">Deep Dives</option>
                                                                <option value="giggles">Giggles</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup></Col>
                                                <Col xl={6} lg={12} md={12}>

                                                    <FormGroup row>
                                                        <Label for="level" sm={3}>
                                                            Level
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="level" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        level: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                {
                                                                    (this.state.filter.type === 'quickpeeks' || this.state.filter.type === 'deepdives') &&
                                                                    <React.Fragment>
                                                                        <option value="beginner">Beginner</option>
                                                                        <option value="intermediate">Intermediate</option>
                                                                        <option value="advanced">Advanced</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="All">All</option>
                                                                    </React.Fragment>
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup></Col>

                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="category" sm={3}>
                                                            Category
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="select"
                                                                name="category"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            category: event.target.value
                                                                        }
                                                                    }), () => {
                                                                        if (this.state.filter.category === '') {
                                                                            this.setState({
                                                                                filter: {
                                                                                    ...this.state.filter,
                                                                                    subCategory: "",
                                                                                }
                                                                            })
                                                                        }
                                                                    });
                                                                    this.getSubCategory(event.target.value);
                                                                }}>
                                                                <option value="">Select</option>
                                                                {this.state.categories.map((category) => {
                                                                    return (<option value={category._id} key={category._id}>{category.name}</option>)
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup></Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="subCategories" sm={3}>
                                                            Sub-Category
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                type="select"
                                                                name="subCategories"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            subCategory: event.target.value
                                                                        }
                                                                    }));
                                                                }
                                                                }>
                                                                <option value="">Select</option>
                                                                {this.state.subCategories.map((subcategory) => {
                                                                    return (<option value={subcategory._id} key={subcategory._id}>{subcategory.name}</option>)
                                                                })}
                                                            </Input>

                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="status" sm={3}>
                                                            Status
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input type="select" name="status" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        status: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="approved">Approved</option>
                                                                <option value="rejected">Rejected</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardHeader>
                                    <Row>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex">
                                                <div sm={4} className="mt-2 pr-2"><Label>Shown</Label></div>
                                                <div sm={4}>
                                                    <Input type="select" name="pageLimit" onChange={(event) => { this.refreshPagination(1, event.target.value) }}>
                                                        <option value={10}>10</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Input>
                                                </div>
                                                <div sm={4} className="mt-2 pl-2"><Label>entries</Label></div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-2 pr-2">
                                                    <Label>Showing {!this.state.totalPosts ? 0 : ((this.state.page * this.state.limit) - (this.state.limit - 1))}  to {(this.state.page * this.state.limit) > this.state.totalPosts ? this.state.totalPosts : (this.state.page * this.state.limit)} of {this.state.totalPosts}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-end">
                                                <div sm={4} className="pr-2"><Button className="rounded-circle" disabled={this.state.page <= 1} onClick={(event) => this.refreshPagination(this.state.page - 1, this.state.limit)}><MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft></Button></div>
                                                <div sm={4}>
                                                    <Input type="select" value={this.state.page} name="filter" onChange={(event) => { this.refreshPagination(event.target.value, this.state.limit) }}>
                                                        {
                                                            this.state.pageNumbers.map((pageNumber, index) => (
                                                                <option value={pageNumber} key={index}>{pageNumber}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                                <div sm={4} className="pl-2"><Button className="rounded-circle" disabled={this.state.page >= this.state.pages} onClick={(event) => this.refreshPagination(this.state.page + 1, this.state.limit)}><MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight></Button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Source</th>
                                                <th>Type</th>
                                                <th>Level</th>
                                                <th>Category</th>
                                                <th>SubCategory</th>
                                                <th>Posted By</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Video Status</th>
                                                <th>Flags</th>
                                                <th>Actions</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.posts && this.state.posts.length > 0
                                                    ? this.state.posts.map((post, index) => (
                                                        <tr key={index}>
                                                            <td><Button color="link" style={{ textAlign: 'left' }} onClick={(event) => this.getPostInfo(post._id)}>{post.title}</Button></td>
                                                            <td>{GeneralFunctions.capitalize(post.source)}</td>
                                                            <td>{GeneralFunctions.capitalize(post.type)}</td>
                                                            <td>{post.type != 'giggles' && GeneralFunctions.capitalize(post.level)}</td>
                                                            <td>{post.type != 'giggles' && post.category}</td>
                                                            <td>{post.type != 'giggles' && post.subCategory}</td>
                                                            <td>{post.owner.firstName} {post.owner.middleName || ''} {post.owner.lastName || ''}</td>
                                                            <td>{<Moment format="MM/DD/YYYY">{post.createdAt}</Moment>}</td>
                                                            {
                                                                this.state.postStatusId === post._id ?
                                                                    <td><Input type="select" name="status" value={post.status} onChange={(event) => { this.changeStatus(post._id, event.target.value) }} >
                                                                        <option value="">Select</option>
                                                                        <option value="approved">Approve</option>
                                                                        <option value="rejected">Reject</option>
                                                                    </Input>
                                                                    </td>
                                                                    : <td>
                                                                        {
                                                                            post.status == 'pending' &&
                                                                            <Badge color="warning" pill>{GeneralFunctions.capitalize(post.status)}</Badge>
                                                                        }
                                                                        {
                                                                            post.status == 'approved' &&
                                                                            <Badge color="success" pill>{GeneralFunctions.capitalize(post.status)}</Badge>
                                                                        }
                                                                        {
                                                                            post.status == 'rejected' &&
                                                                            <Badge color="danger" pill>{GeneralFunctions.capitalize(post.status)}</Badge>
                                                                        }
                                                                        <Button color="link" onClick={() => this.setPostId(post._id)}>Change</Button>
                                                                    </td>
                                                            }
                                                            <td>
                                                                {
                                                                    !post.videoUploaded ?
                                                                        <Badge color="warning" pill>Uploading</Badge>
                                                                        :
                                                                        (
                                                                            <React.Fragment>
                                                                                {
                                                                                    post.videoTranscodeStatus == 'processing' &&
                                                                                    <Badge color="warning" pill>{GeneralFunctions.capitalize(post.videoTranscodeStatus)}</Badge>
                                                                                }
                                                                                {
                                                                                    post.videoTranscodeStatus == 'available' &&
                                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(post.videoTranscodeStatus)}</Badge>
                                                                                }
                                                                                {
                                                                                    post.videoTranscodeStatus == 'error' &&
                                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(post.videoTranscodeStatus)}</Badge>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </td>
                                                            <td>{post && post.flags && post.flags.length}</td>
                                                            <td><Button onClick={(event) => this.toggleRemovePostConfirmationModal(post._id)}><MdDeleteForever size="20px"></MdDeleteForever></Button></td>
                                                            <td>
                                                                {
                                                                    process.env.REACT_APP_EOS_URL && post.eosTransactionId &&
                                                                    <FiExternalLink style={{ fontSize: 20, marginRight: 5, cursor: 'pointer' }} onClick={() => window.open(process.env.REACT_APP_EOS_URL + post.eosTransactionId, "_blank")}></FiExternalLink>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="13" className="text-center">No posts found.</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    this.state.removePostConfirmationModal ?
                                        <Modal
                                            isOpen={this.state.removePostConfirmationModal}
                                            toggle={(event) => this.toggleRemovePostConfirmationModal()}
                                            className="modal-md">
                                            <ModalHeader toggle={(event) => this.toggleRemovePostConfirmationModal()}></ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to delete this post ?
                                        </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.toggleRemovePostConfirmationModal()}>Cancel</Button>{' '}
                                                <Button color="primary" onClick={(event) => { event.preventDefault(); this.toggleRemovePostConfirmationModal(); this.removePost(selectedPostId) }}>
                                                    Delete
                                            </Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                                {
                                    this.state.postDetailModal ?
                                        <Modal
                                            isOpen={this.state.postDetailModal}
                                            toggle={(event) => this.closeModal()}
                                            className="modal-lg">
                                            <ModalHeader toggle={(event) => this.closeModal()}>Video Details</ModalHeader>
                                            <ModalBody>
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Row>
                                                            <Label sm={3}>Title :</Label>
                                                            <Label sm={9}>{this.state.postDetail.title}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Source :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.source)}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Type :</Label>
                                                            <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.type)}</Label>
                                                        </Row>
                                                        {this.state.postDetail.type != 'giggles' &&
                                                            (<Row>
                                                                <Label sm={3}>Level :</Label>
                                                                <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.level)}</Label>
                                                            </Row>)
                                                        }
                                                        {
                                                            this.state.postDetail.type != 'giggles' &&
                                                            <React.Fragment>
                                                                <Row>
                                                                    <Label sm={3}>Category :</Label>
                                                                    <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.category)}</Label>
                                                                </Row>
                                                                <Row>
                                                                    <Label sm={3}>Sub Category :</Label>
                                                                    <Label sm={9}>{GeneralFunctions.capitalize(this.state.postDetail.subCategory)}</Label>
                                                                </Row>
                                                            </React.Fragment>
                                                        }
                                                        <Row>
                                                            <Label sm={3}>Posted By :</Label>
                                                            <Label sm={9}>{this.state.postDetail.owner.firstName} {this.state.postDetail.owner.middleName || ''} {this.state.postDetail.owner.lastName || ''}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Date :</Label>
                                                            <Label sm={9}>{<Moment format="MM/DD/YYYY">{this.state.postDetail.createdAt}</Moment>}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Status :</Label>
                                                            <Label sm={9}>
                                                                {
                                                                    this.state.postDetail.status == 'pending' &&
                                                                    <Badge color="warning" pill>{GeneralFunctions.capitalize(this.state.postDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.postDetail.status == 'approved' &&
                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.postDetail.status)}</Badge>
                                                                }
                                                                {
                                                                    this.state.postDetail.status == 'rejected' &&
                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(this.state.postDetail.status)}</Badge>
                                                                }
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Video Status :</Label>
                                                            <Label sm={9}>
                                                                {
                                                                    !this.state.postDetail.videoUploaded ?
                                                                        <Badge color="warning" pill>Uploading</Badge>
                                                                        :
                                                                        (
                                                                            <React.Fragment>
                                                                                {
                                                                                    this.state.postDetail.videoTranscodeStatus == 'processing' &&
                                                                                    <Badge color="warning" pill>{GeneralFunctions.capitalize(this.state.postDetail.videoTranscodeStatus)}</Badge>
                                                                                }
                                                                                {
                                                                                    this.state.postDetail.videoTranscodeStatus == 'available' &&
                                                                                    <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.postDetail.videoTranscodeStatus)}</Badge>
                                                                                }
                                                                                {
                                                                                    this.state.postDetail.videoTranscodeStatus == 'error' &&
                                                                                    <Badge color="danger" pill>{GeneralFunctions.capitalize(this.state.postDetail.videoTranscodeStatus)}</Badge>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                }
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Media :</Label>
                                                            <Label sm={9}>
                                                                {
                                                                    !this.state.showVideo &&
                                                                    <Button onClick={(event) => this.setState({ showVideo: true })}> Play Video</Button>
                                                                }
                                                                {
                                                                    this.state.showVideo &&
                                                                    <ReactPlayer
                                                                        url={this.state.postDetail.wowzaVideoKey ? process.env.REACT_APP_SERVER_VIDEO_URL + "/vod/mp4:" + this.state.postDetail.wowzaVideoKey + "_transcoded.mp4/playlist.m3u8" : process.env.REACT_APP_SERVER_URL + "/media/posts/" + this.state.postDetail._id + '/' + this.state.postDetail._id + '_video' + this.state.postDetail.videoFileExt}
                                                                        playing
                                                                        config={{ file: { attributes: { controlsList: 'nodownload', autoPlay: true, muted: false }, forceHLS: (this.state.postDetail.wowzaVideoKey ? true : false) } }}
                                                                        width="100%"
                                                                        height="400px"
                                                                        controls={true}
                                                                        style={{ overflow: 'hidden' }}
                                                                    />
                                                                }
                                                            </Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Total Likes :</Label>
                                                            <Label sm={9}>{this.state.postDetail.totalLikes}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Total Comments :</Label>
                                                            <Label sm={9}>{this.state.postDetail.totalComments}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Total Ratings :</Label>
                                                            <Label sm={9}>{this.state.postDetail.totalRatings}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Total Flags :</Label>
                                                            <Label sm={9}>{this.state.postDetail.totalFlags}</Label>
                                                        </Row>
                                                        <Row>
                                                            <Label sm={3}>Average Rating :</Label>
                                                            <Label sm={9}>{this.state.postDetail.averageRating || 0}</Label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <h4>Comments</h4>
                                                        <Card style={{ height: this.state.postDetail.comments.length ? '300px' : '100px', overflowY: 'scroll', width: '100%', display: 'block' }}>
                                                            <Table responsive striped hover>
                                                                <thead >
                                                                    <tr>
                                                                        <th>Comment</th>
                                                                        <th>Posted By</th>
                                                                        <th>Date</th>
                                                                        <th>Flag Count</th>
                                                                        <th>Replies</th>
                                                                        <th>Reactions</th>
                                                                        <th>Action</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    {this.state.postDetail.comments && this.state.postDetail.comments.length > 0 ? this.state.postDetail.comments.map((comment, index) => (
                                                                        <tr key={index}>
                                                                            <td>{comment.comment}</td>
                                                                            <td>{comment.owner.firstName + " " + comment.owner.lastName}</td>
                                                                            <td><Moment format="MM/DD/YYYY">{comment.createdAt}</Moment></td>
                                                                            <td>{comment.flags && comment.flags.length}</td>
                                                                            <td><Link style={{ textDecoration: 'none' }} to='#' onClick={comment.replies.length ? () => this.handleReplies(comment) : e => e.preventDefault()}>{comment.replies.length}</Link></td>
                                                                            <td className="reaction"><p>Agree - {this.cheackReactionCount(comment.reactions, 'agree')}</p><p> Neutral - {this.cheackReactionCount(comment.reactions, 'neutral')}</p><p> Disagree - {this.cheackReactionCount(comment.reactions, 'disagree')}</p></td>
                                                                            <td><Button onClick={(e) => { e.preventDefault(); this.toggleRemoveCommentConfirmationModal(this.state.postDetail._id, comment._id) }}><MdDeleteForever size="20px"></MdDeleteForever></Button></td>
                                                                            <td>
                                                                                {
                                                                                    process.env.REACT_APP_EOS_URL && comment.eosTransactionId &&
                                                                                    <FiExternalLink style={{ fontSize: 20, marginRight: 5, cursor: 'pointer' }} onClick={() => window.open(process.env.REACT_APP_EOS_URL + comment.eosTransactionId, "_blank")}></FiExternalLink>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                        :
                                                                        <tr><td colSpan="8" className="text-center">No comments found.</td></tr>
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <h4>Ratings</h4>
                                                        <Card style={{ height: this.state.postDetail.ratings.length ? '300px' : '100px', overflowY: 'scroll', width: '100%', display: 'block' }}>
                                                            <Table striped hover responsive >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Rating</th>
                                                                        <th>Rated By</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.postDetail.ratings && this.state.postDetail.ratings.length ?
                                                                        this.state.postDetail.ratings.map((rating, index) => (
                                                                            <tr key={index}>
                                                                                <td>{rating && rating.rating}</td>
                                                                                <td>{rating.owner && rating.owner.firstName + " " + rating.owner.lastName}</td>
                                                                                <td><Button onClick={(e) => { e.preventDefault(); this.toggleRemoveRatingConfirmationModal(this.state.postDetail._id, rating._id) }}><MdDeleteForever size="20px"></MdDeleteForever></Button></td>
                                                                            </tr>
                                                                        ))
                                                                        :
                                                                        <tr><td colSpan="3" className="text-center">No ratings found.</td></tr>
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.closeModal()}>Cancel</Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                                {
                                    <Modal isOpen={this.state.repliesModel} toggle={this.toggleRepliesModal} className="modal-lg">
                                        <ModalHeader toggle={this.toggleRepliesModal}>Replies</ModalHeader>
                                        <ModalBody>
                                            <Card>
                                                <Table responsive striped hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Reply</th>
                                                            <th>Posted By</th>
                                                            <th>Date</th>
                                                            <th>Flag Count</th>
                                                            <th>Reactions</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.comment.replies && this.state.comment.replies.length ? this.state.comment.replies.map((reply, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{reply.reply}</td>
                                                                        <td>{reply.owner.firstName} {reply.owner.lastName}</td>
                                                                        <td><Moment format="MM/DD/YYYY">{reply.createdAt}</Moment></td>
                                                                        <td>{reply.flags && reply.flags.length}</td>
                                                                        <td className="reaction"><p>Agree - {this.cheackReactionCount(reply.reactions, 'agree')}</p><p> Neutral - {this.cheackReactionCount(reply.reactions, 'neutral')} </p><p> Disagree - {this.cheackReactionCount(reply.reactions, 'disagree')}</p></td>
                                                                        <td><Button onClick={(e) => { e.preventDefault(); this.toggleRemoveReplyConfirmationModal(this.state.postDetail._id, this.state.comment._id, reply._id) }}><MdDeleteForever size="20px"></MdDeleteForever></Button></td>
                                                                    </tr>
                                                                )
                                                            })
                                                                :
                                                                <tr><td colSpan="6" className="text-center">No reply found.</td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card>
                                        </ModalBody>
                                    </Modal>
                                }
                            </Card>
                        </Col>
                    </Row>
                    {
                        this.state.removeCommentConfirmationModal &&
                        <Modal
                            isOpen={this.state.removeCommentConfirmationModal}
                            toggle={(event) => this.toggleRemoveCommentConfirmationModal()}
                            className="modal-md">
                            <ModalHeader toggle={(event) => this.toggleRemoveCommentConfirmationModal()}></ModalHeader>
                            <ModalBody>
                                Are you sure you want to delete this comment ?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={(event) => this.toggleRemoveCommentConfirmationModal()}>Cancel</Button>{' '}
                                <Button color="primary" onClick={(event) => { event.preventDefault(); this.toggleRemoveCommentConfirmationModal(); this.removeComment() }}>
                                    Delete
                                    </Button>
                            </ModalFooter>
                        </Modal>
                    }
                    {
                        this.state.removeReplyConfirmationModal &&
                        <Modal
                            isOpen={this.state.removeReplyConfirmationModal}
                            toggle={(event) => this.toggleRemoveReplyConfirmationModal()}
                            className="modal-md">
                            <ModalHeader toggle={(event) => this.toggleRemoveReplyConfirmationModal()}></ModalHeader>
                            <ModalBody>
                                Are you sure you want to delete this reply ?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={(event) => this.toggleRemoveReplyConfirmationModal()}>Cancel</Button>{' '}
                                <Button color="primary" onClick={(event) => { event.preventDefault(); this.toggleRemoveReplyConfirmationModal(); this.removeReply() }}>
                                    Delete
                                    </Button>
                            </ModalFooter>
                        </Modal>
                    }
                    {
                        this.state.removeRatingConfirmationModal &&
                        <Modal
                            isOpen={this.state.removeRatingConfirmationModal}
                            toggle={(event) => this.toggleRemoveRatingConfirmationModal()}
                            className="modal-md">
                            <ModalHeader toggle={(event) => this.toggleRemoveRatingConfirmationModal()}></ModalHeader>
                            <ModalBody>
                                Are you sure you want to delete this rating ?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={(event) => this.toggleRemoveRatingConfirmationModal()}>Cancel</Button>{' '}
                                <Button color="primary" onClick={(event) => { event.preventDefault(); this.toggleRemoveRatingConfirmationModal(); this.removeRating() }}>
                                    Delete
                                    </Button>
                            </ModalFooter>
                        </Modal>
                    }
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div >
        );
    }
}
export default Posts;
