import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdDeleteForever, MdModeEdit } from 'react-icons/md';
import * as Server from '../Server';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';

let selectedAdminId = null;

class Admins extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            collapseFilter: false,
            createOrEditAdminModal: false,
            removeUserConfirmationModal: false,
            admins: [],
            roles: [],
            filter: {
                name: "",
                email: "",
                roleId: ""
            },
            adminUser: {},
            firstNameValidater: '',
            lastNameValidater: '',
            emailValidater: '',
            page: 1,
            limit: 10,
            totalAdmins: 0,
            pages: 0,
            pageNumbers: []
        };

        this.createOrEditAdminModalToggle = this.createOrEditAdminModalToggle.bind(this);
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('admins')) this.props.history.push('/dashboard');
        this.getRoleDetails();
        this.getAdminsDetails();
    }

    getAdminsDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/',
                method: 'POST',
                data: { filter: this.state.filter, page: this.state.page, limit: this.state.limit }
            });
            this.setState({ showLoader: false });
            this.setState({ admins: response.admins, totalAdmins: response.totalAdmins, pages: response.pages });
            let pageNumbers = [];
            for (let page = 1; page <= response.pages; page++) {
                pageNumbers.push(page);
            }
            this.setState({ pageNumbers: pageNumbers });
            this.setState({ adminUser: {} });
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    getRoleDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'role/',
                method: 'POST',
                data: { filter: this.state.filter }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.setState({ roles: response.roles });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    createAdmin = async () => {
        this.setState({ adminUser: {} });
        this.createOrEditAdminModalToggle();
    }

    updateAdmin = async (admin) => {
        this.setState({ adminUser: admin });
        this.createOrEditAdminModalToggle();
    }

    onSubmit = async () => {
        try {
            let url = this.state.adminUser._id ? 'admin/updateAdmin' : 'admin/createAdmin'
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: url,
                method: 'POST',
                data: this.state.adminUser
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.createOrEditAdminModalToggle();
                this.getAdminsDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    search() {
        this.setState({ page: parseInt(1, 10) }, () => {
            this.getAdminsDetails();
        });
    }

    refreshPagination = async (page, limit) => {
        this.setState({ page: parseInt(page, 10), limit: limit }, () => {
            this.getAdminsDetails();
        });
    }

    componentWillMount() {
        this.getAdminsDetails();
    }

    toggleRemoveAdminUserConfirmationModal = async (id) => {
        if (!selectedAdminId && id) {
            selectedAdminId = id;
        }
        this.setState({
            removeUserConfirmationModal: !this.state.removeUserConfirmationModal
        });
    }

    removeAdmin = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/removeAdmin/' + id,
                method: 'DELETE'
            });
            if (response) {
                this.setState({ showLoader: false });
                selectedAdminId = null;
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.getAdminsDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    createOrEditAdminModalToggle() {
        this.setState(prevState => ({
            createOrEditAdminModal: !prevState.createOrEditAdminModal
        }));
    }

    onChange(key, value) {
        let adminUser = {
            [key]: value
        };
        if (key && value) {
            if (key === 'firstName') {
                this.firstName = true;
            }
            if (key === 'lastName') {
                this.lastName = true;
            }
            if (key === 'email') {
                this.email = true;
            }
        }

        if (key === 'firstName') {
            if (adminUser.firstName.length === 0) {
                this.setState({
                    firstNameValidater: '*Required'
                });
            };
        }
        if (key === 'lastName') {
            if (adminUser.lastName.length === 0) {
                this.setState({ lastNameValidater: '*Required' })
            };
        }
        if (key === 'email') {
            if (adminUser.email.length === 0) {
                this.setState({ emailValidater: '*Required' })
            };
        }
    }

    render() {
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Admins"
                    breadcrumbs={[{ name: 'Admins', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>

                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.search() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={2}>
                                                            Name
                                                </Label>
                                                        <Col sm={10}>
                                                            <Input type="text" name="firstName"
                                                                placeholder="Enter Name"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            name: event.target.value
                                                                        }
                                                                    }));
                                                                }}>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="exampleEmail" sm={2}>
                                                            Email
                                                </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                type="email"
                                                                name="email"
                                                                placeholder="Enter Email"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            email: event.target.value
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="exampleEmail" sm={2}>
                                                            Role
                                                </Label>
                                                        <Col sm={10}>
                                                            <Input type="select" name="role"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            roleId: event.target.value
                                                                        }
                                                                    }));
                                                                }}><option value="">Select</option>
                                                                {
                                                                    this.state.roles && this.state.roles.length > 0
                                                                        ? this.state.roles.map((role, index) => (
                                                                            <option key={index} value={role._id}>{role.name}</option>
                                                                        ))
                                                                        : null
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="float-right" onClick={(event) => this.createAdmin()} title="Create Admins">Add Admin</Button>
                            <Modal
                                isOpen={this.state.createOrEditAdminModal}
                                toggle={this.createOrEditAdminModalToggle}
                                className="modal-lg">
                                <ModalHeader toggle={this.createOrEditAdminModalToggle}>{this.state.adminUser._id ? "Update" : "Create"} Admin User</ModalHeader>
                                <Form onSubmit={(event) => { event.preventDefault(); this.onSubmit() }}>
                                    <ModalBody>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        First Name
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input type="text" name="firstName"
                                                            placeholder="Enter First Name"
                                                            value={this.state.adminUser.firstName}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("firstName", event.target.value);
                                                                this.setState(prevState => ({
                                                                    adminUser: {
                                                                        ...prevState.adminUser,
                                                                        firstName: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {this.state.firstNameValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Last Name
                                                </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Enter Last Name"
                                                            value={this.state.adminUser.lastName}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("lastName", event.target.value);
                                                                this.setState(prevState => ({
                                                                    adminUser: {
                                                                        ...prevState.adminUser,
                                                                        lastName: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {this.state.lastNameValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Email Id
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            readOnly={this.state.adminUser._id ? true : false}
                                                            value={this.state.adminUser.email}
                                                            placeholder="Enter Email"
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("email", event.target.value);
                                                                this.setState(prevState => ({
                                                                    adminUser: {
                                                                        ...prevState.adminUser,
                                                                        email: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {this.state.emailValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Phone No
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="text"
                                                            name="mobileNumber"
                                                            value={this.state.adminUser.mobileNumber}
                                                            placeholder="Enter Phone no"
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    adminUser: {
                                                                        ...prevState.adminUser,
                                                                        mobileNumber: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Role
                                                </Label>
                                                    <Col sm={10}>
                                                        <Input type="select" name="role"
                                                            value={this.state.adminUser.roleId}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    adminUser: {
                                                                        ...prevState.adminUser,
                                                                        roleId: event.target.value
                                                                    }
                                                                }));
                                                            }}><option value="">Select</option>
                                                            {
                                                                this.state.roles && this.state.roles.length > 0
                                                                    ? this.state.roles.map((role, index) => (
                                                                        <option key={index} value={role._id}>{role.name}</option>
                                                                    ))
                                                                    : null
                                                            }
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.createOrEditAdminModalToggle}>Cancel</Button>{' '}
                                        <Button color="primary" type="submit">
                                            {this.state.adminUser._id ? "Update" : "Create"}
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardHeader>
                                    <Row>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex">
                                                <div sm={4} className="mt-2 pr-2"><Label>Shown</Label></div>
                                                <div sm={4}>
                                                    <Input type="select" name="pageLimit" onChange={(event) => { this.refreshPagination(1, event.target.value) }}>
                                                        <option value={10}>10</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Input>
                                                </div>
                                                <div sm={4} className="mt-2 pl-2"><Label>entries</Label></div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-2 pr-2">
                                                    <Label>Showing {!this.state.totalAdmins ? 0 : ((this.state.page * this.state.limit) - (this.state.limit - 1))}  to {(this.state.page * this.state.limit) > this.state.totalAdmins ? this.state.totalAdmins : (this.state.page * this.state.limit)} of {this.state.totalAdmins}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-end">
                                                <div sm={4} className="pr-2"><Button className="rounded-circle" disabled={this.state.page <= 1} onClick={(event) => this.refreshPagination(this.state.page - 1, this.state.limit)}><MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft></Button></div>
                                                <div sm={4}>
                                                    <Input type="select" value={this.state.page} name="filter" onChange={(event) => { this.refreshPagination(event.target.value, this.state.limit) }}>
                                                        {
                                                            this.state.pageNumbers.map((pageNumber, index) => (
                                                                <option value={pageNumber} key={index}>{pageNumber}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                                <div sm={4} className="pl-2"><Button className="rounded-circle" disabled={this.state.page >= this.state.pages} onClick={(event) => this.refreshPagination(this.state.page + 1, this.state.limit)}><MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight></Button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.admins && this.state.admins.length > 0
                                                    ? this.state.admins.map((admin, index) => (
                                                        <tr key={index}>
                                                            <td>{GeneralFunctions.capitalize(admin.firstName)}  {GeneralFunctions.capitalize(admin.lastName)}</td>
                                                            <td>{admin.email}</td>
                                                            <td>{GeneralFunctions.capitalize(admin.role && admin.role.name)}</td>
                                                            <td><Moment format="MM/DD/YYYY">{admin.createdAt}</Moment></td>
                                                            <td>
                                                                <Button color="primary" onClick={(event) => this.updateAdmin(admin)}><MdModeEdit size="20px"></MdModeEdit></Button>{' '}
                                                                <Button onClick={(event) => this.toggleRemoveAdminUserConfirmationModal(admin._id)}><MdDeleteForever size="20px"></MdDeleteForever></Button>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="5" className="text-center">No admins found.</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    this.state.removeUserConfirmationModal ?
                                        <Modal
                                            isOpen={this.state.removeUserConfirmationModal}
                                            toggle={(event) => this.toggleRemoveAdminUserConfirmationModal()}
                                            className="modal-md">
                                            <ModalHeader toggle={(event) => this.toggleRemoveAdminUserConfirmationModal()}></ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to delete this admin user ?
                                        </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.toggleRemoveAdminUserConfirmationModal()}>Cancel</Button>{' '}
                                                <Button color="primary" onClick={(event) => { this.toggleRemoveAdminUserConfirmationModal(); this.removeAdmin(selectedAdminId) }}>
                                                    Delete
                                            </Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div>
        );
    }
}
export default Admins;
