import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDeleteForever, MdModeEdit } from 'react-icons/md';
import * as Server from '../Server';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';

let selectedAdminId = null;

class RolesAndPermissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            collapseFilter: false,
            createOrEditRoleModal: false,
            removeRoleConfirmationModal: false,
            setPermissionsModal: false,
            roles: [],
            filter: {
                name: ""
            },
            role: {},
            nameValidater: '',
            response: "",
            pages: []
        };
        this.toggleSetPermissionsModal = this.toggleSetPermissionsModal.bind(this);
        this.createOrEditRoleModalToggle = this.createOrEditRoleModalToggle.bind(this);
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('rolesAndPermissions')) this.props.history.push('/dashboard');
        this.getRoleDetails();
    }

    getRoleDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'role/',
                method: 'POST',
                data: { filter: this.state.filter }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.setState({ response: response, roles: response.roles });
                this.setState({ role: {} });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    toggleSetPermissionsModal() {
        this.setState(prevState => ({
            setPermissionsModal: !prevState.setPermissionsModal
        }));
    }

    setPermission = async () => {
        this.toggleSetPermissionsModal();
    }

    createRole = async () => {
        this.setState({ role: {} });
        this.createOrEditRoleModalToggle();
    }

    updateRole = async (role) => {
        this.setState({ role: role });
        this.createOrEditRoleModalToggle();
    }

    onSubmit = async () => {
        try {
            let url = this.state.role._id ? 'role/update' : 'role/create'
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: url,
                method: 'POST',
                data: this.state.role
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.createOrEditRoleModalToggle();
                this.getRoleDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    toggleRemoveRoleConfirmationModal = async (id) => {
        if (!selectedAdminId && id) {
            selectedAdminId = id;
        }
        this.setState({
            removeRoleConfirmationModal: !this.state.removeRoleConfirmationModal
        });
    }

    removeRole = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'role/' + id,
                method: 'DELETE'
            });
            if (response) {
                this.setState({ showLoader: false });
                selectedAdminId = null;
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.getRoleDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    onCheckboxChecked = async (key, permission, obj) => {
        try {
            let url = obj.permissions.includes(key) ? 'role/removePermission' : 'role/addPermission';
            this.setState({ showLoader: true });
            let _id = obj._id;
            let response = await Server.request({
                url: url,
                method: 'POST',
                data: { _id, permission }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.getRoleDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    createOrEditRoleModalToggle() {
        this.setState(prevState => ({
            createOrEditRoleModal: !prevState.createOrEditRoleModal
        }));
    }

    onChange(key, value) {
        let role = {
            [key]: value
        };
        if (key && value) {
            if (key === 'name') {
                this.name = true;
            }
        }

        if (key === 'name') {
            if (role.name.length === 0) {
                this.setState({
                    nameValidater: '*Required'
                });
            };
        }
    }
    render() {
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Roles and Permissions"
                    breadcrumbs={[{ name: 'Roles and Permissions', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>
                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.getRoleDetails() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={2}>
                                                            Name
                                                    </Label>
                                                        <Col sm={10}>
                                                            <Input type="text" name="name"
                                                                placeholder="Enter Name"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            name: event.target.value
                                                                        }
                                                                    }));
                                                                }}>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="float-right" onClick={(event) => this.setPermission()}>Set Permissions</Button>
                            <Button className="float-right" style={{ marginRight: 10 }} onClick={(event) => this.createRole()}>Add Role</Button>
                            <Modal
                                isOpen={this.state.createOrEditRoleModal}
                                toggle={this.createOrEditRoleModalToggle}
                                className="modal-lg">
                                <ModalHeader toggle={this.createOrEditRoleModalToggle}>{this.state.role._id ? "Update" : "Create"} Role</ModalHeader>
                                <Form onSubmit={(event) => { event.preventDefault(); this.onSubmit() }}>
                                    <ModalBody>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Name
                                                    </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            placeholder="Enter Name"
                                                            value={this.state.role.name}
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("name", event.target.value);
                                                                this.setState(prevState => ({
                                                                    role: {
                                                                        ...prevState.role,
                                                                        name: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {this.state.nameValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Description
                                                        </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="text"
                                                            name="description"
                                                            value={this.state.role.description}
                                                            placeholder="Enter Description"
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    role: {
                                                                        ...prevState.role,
                                                                        description: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.createOrEditRoleModalToggle}>Cancel</Button>{' '}
                                        <Button color="primary" type="submit">
                                            {this.state.role._id ? "Update" : "Create"}
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Modal>

                            <Modal
                                isOpen={this.state.setPermissionsModal}
                                toggle={this.toggleSetPermissionsModal}
                                className="modal-lg">
                                <ModalHeader toggle={this.toggleSetPermissionsModal}>Set Permission</ModalHeader>
                                <ModalBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Roles</th>
                                                <th>Lounges</th>
                                                <th>Users</th>
                                                <th>Videos</th>
                                                <th>Live Workouts</th>
                                                <th>Takeaways</th>
                                                <th>Rewards</th>
                                                <th>Notifications</th>
                                                <th>Admins</th>
                                                <th>Roles and Permissions</th>
                                                <th>Logs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.roles && this.state.roles.length > 0
                                                    ? this.state.roles.map((role, index) => (
                                                        <tr key={index}>
                                                            <td>{role.name ? GeneralFunctions.capitalize(role.name) : null}</td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="lounges" value="lounges" checked={role.permissions ? role.permissions.includes("lounges") : null} onChange={(event) => { this.onCheckboxChecked("lounges", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="users" value="users" checked={role.permissions ? role.permissions.includes("users") : null} onChange={(event) => { this.onCheckboxChecked("users", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="posts" value="posts" checked={role.permissions ? role.permissions.includes("posts") : null} onChange={(event) => { this.onCheckboxChecked("posts", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="workoutClasses" value="workoutClasses" checked={role.permissions ? role.permissions.includes("workoutClasses") : null} onChange={(event) => { this.onCheckboxChecked("workoutClasses", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="takeaways" value="takeaways" checked={role.permissions ? role.permissions.includes("takeaways") : null} onChange={(event) => { this.onCheckboxChecked("takeaways", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="rewards" value="rewards" checked={role.permissions ? role.permissions.includes("rewards") : null} onChange={(event) => { this.onCheckboxChecked("rewards", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="notifications" value="notifications" checked={role.permissions ? role.permissions.includes("notifications") : null} onChange={(event) => { this.onCheckboxChecked("notifications", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="admins" value="admins" checked={role.permissions ? role.permissions.includes("admins") : null} onChange={(event) => { this.onCheckboxChecked("admins", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="rolesAndPermissions" value="rolesAndPermissions" checked={role.permissions ? role.permissions.includes("rolesAndPermissions") : null} onChange={(event) => { this.onCheckboxChecked("rolesAndPermissions", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Col md={1}>
                                                                        <Input type="checkbox" name="logs" value="logs" checked={role.permissions ? role.permissions.includes("logs") : null} onChange={(event) => { this.onCheckboxChecked("logs", event.target.value, role) }} />
                                                                    </Col>
                                                                </FormGroup>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="8" className="text-center">No roles found.</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </ModalBody>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardBody>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.roles && this.state.roles.length > 0
                                                    ? this.state.roles.map((role, index) => (
                                                        <tr key={index}>
                                                            <td>{role.name ? GeneralFunctions.capitalize(role.name) : null}</td>
                                                            <td>{role.description}</td>
                                                            <td>{role.createdAt ? <Moment format="MM/DD/YYYY">{role.createdAt}</Moment> : null}</td>
                                                            <td>
                                                                <Button color="primary" onClick={(event) => this.updateRole(role)}><MdModeEdit size="20px"></MdModeEdit></Button>{' '}
                                                                <Button onClick={(event) => this.toggleRemoveRoleConfirmationModal(role._id)}><MdDeleteForever size="20px"></MdDeleteForever></Button>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="6" className="text-center">No Roles found</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    this.state.removeRoleConfirmationModal ?
                                        <Modal
                                            isOpen={this.state.removeRoleConfirmationModal}
                                            toggle={(event) => this.toggleRemoveRoleConfirmationModal()}
                                            className="modal-md">
                                            <ModalHeader toggle={(event) => this.toggleRemoveRoleConfirmationModal()}></ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to delete this role ?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.toggleRemoveRoleConfirmationModal()}>Cancel</Button>{' '}
                                                <Button color="primary" onClick={(event) => { this.toggleRemoveRoleConfirmationModal(); this.removeRole(selectedAdminId) }}>
                                                    Delete
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div>
        );
    }
}
export default RolesAndPermissions;
