import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Badge, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDeleteForever, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import * as Server from '../Server';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';

let selectedNotificationId = null;
class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.notificationsRefreshTimer = null;
        this.state = {
            showLoader: false,
            collapseFilter: false,
            notificationModal: false,
            removeNotificationConfirmationModal: false,
            notificationStatusId: null,
            notifications: [],
            filter: {
                status: "",
                title: ""
            },
            notification: {
                title: "",
                message: "",
                sendTo: "all"
            },
            titleValidater: '',
            messageValidater: '',
            page: 1,
            limit: 10,
            totalNotifications: 0,
            pages: 0,
            pageNumbers: []
        };
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('notifications')) this.props.history.push('/dashboard');
        this.getNotificationsDetails(true);
        this.refreshNotifications();
    }

    componentWillUnmount() {
        clearInterval(this.notificationsRefreshTimer);
    }

    refreshNotifications() {
        this.notificationsRefreshTimer = setInterval(async () => {
            try {
                this.getNotificationsDetails(false);
            } catch (error) { }
        }, 10000);
    }

    getNotificationsDetails = async (showLoader) => {
        try {
            if (showLoader) {
                this.setState({ showLoader: true });
            }
            let filter = this.state.filter;
            let page = this.state.page;
            let limit = this.state.limit;
            let response = await Server.request({
                url: 'admin/notification/search',
                method: 'POST',
                data: { filter, page, limit }
            });
            this.setState({ showLoader: false });
            this.setState({ notifications: response.notifications, totalNotifications: response.totalNotifications, pages: response.pages });
            let pageNumbers = [];
            for (let page = 1; page <= response.pages; page++) {
                pageNumbers.push(page);
            }
            this.setState({ pageNumbers: pageNumbers });
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    toggleCreateNotificationModal = modalType => () => {
        if (!modalType) {
            return this.setState({
                notificationModal: !this.state.notificationModal,
            });
        }
    }

    toggleRemoveNotificationConfirmationModal = async (id) => {
        if (!selectedNotificationId && id) {
            selectedNotificationId = id;
        }
        this.setState({
            removeNotificationConfirmationModal: !this.state.removeNotificationConfirmationModal
        });
    }

    createNotification = async () => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/notification',
                method: 'POST',
                data: this.state.notification
            });
            if (response) {
                this.setState({ showLoader: false });
                this.setState({
                    notification: {
                        title: "",
                        message: "",
                        sendTo: "all"
                    }
                })
                this.notificationSystem.addNotification({
                    message: 'Notification created successfully.',
                    level: 'success',
                });
                this.getNotificationsDetails(true);
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    setNotificationtId = async (_id) => {
        this.setState({ notificationStatusId: _id });
    }

    changeStatus = async (notificationId, value) => {
        try {
            this.setState({ showLoader: true });
            let status = value;
            let response = await Server.request({
                url: 'admin/notification/updateStatus',
                method: 'POST',
                data: { notificationId, status }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: 'Notification status updated successfully.',
                    level: 'success',
                });
                this.setState({ notificationStatusId: null });
                this.getNotificationsDetails(true);
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    removeNotification = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await Server.request({
                url: 'admin/notification/' + id + '/removeNotification',
                method: 'DELETE'
            });
            if (response) {
                this.setState({ showLoader: false });
                selectedNotificationId = null;
                this.notificationSystem.addNotification({
                    message: response.message,
                    level: 'success',
                });
                this.setState({ removeNotificationConfirmationModal: false });
                this.getNotificationsDetails(true);
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    search() {
        this.setState({ page: parseInt(1, 10) }, () => {
            this.getNotificationsDetails(true);
        });
    }

    refreshPagination = async (page, limit) => {
        this.setState({ page: parseInt(page, 10), limit: limit }, () => {
            this.getNotificationsDetails(true);
        });
    }

    getSendToString = (sendTo) => {
        if (!sendTo) return '';
        if (sendTo == 'pingers') return 'Pingers';
        if (sendTo == 'pongers') return 'Pongers';
        if (sendTo == 'basic') return 'Enthusiasts';
        if (sendTo == 'all') return 'All Users'
    }

    onChange(key, value) {
        let notification = {
            [key]: value
        };
        if (key && value) {
            if (key === 'title') {
                this.title = true;
            }
            if (key === 'message') {
                this.newPassword = true;
            }
        }

        if (key === 'title') {
            if (notification.title.length === 0) {
                this.setState({
                    titleValidater: '*Required'
                });
            };
        }
        if (key === 'message') {
            if (notification.message.length === 0) {
                this.setState({ messageValidater: '*Required' })
            };
        }
    }

    render() {
        const disabled = this.state.notification.title.length && this.state.notification.message.length;
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Notifications"
                    breadcrumbs={[{ name: 'Notifications', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>
                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.search() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="title" sm={2}>
                                                            Title
                                                    </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                type="text"
                                                                name="title"
                                                                placeholder="Title"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            title: event.target.value
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="status" sm={2}>
                                                            Status
                                                </Label>
                                                        <Col sm={10}>
                                                            <Input type="select" name="status" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        status: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="sending">Sending</option>
                                                                <option value="sent">Sent</option>
                                                                <option value="failed">Failed</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="float-right" onClick={this.toggleCreateNotificationModal()} title="Create Notification">Add Notification</Button>
                            <Modal
                                isOpen={this.state.notificationModal}
                                toggle={this.toggleCreateNotificationModal()}
                                className="modal-lg">
                                <ModalHeader toggle={this.toggleCreateNotificationModal()}>Create Notification</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={(event) => { event.preventDefault(); this.createNotification() }}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup row>
                                                    <Label for="exampleEmail" sm={2}>
                                                        Title
                                                </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="text"
                                                            name="title"
                                                            placeholder="Enter Title"
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("title", event.target.value);
                                                                this.setState(prevState => ({
                                                                    notification: {
                                                                        ...prevState.notification,
                                                                        title: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {this.state.titleValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleMessage" sm={2}>
                                                        Message
                                                </Label>
                                                    <Col sm={10}>
                                                        <Input
                                                            type="textarea"
                                                            name="Message"
                                                            placeholder="Enter Message"
                                                            onChange={(event) => {
                                                                event.persist();
                                                                this.onChange("message", event.target.value);
                                                                this.setState(prevState => ({
                                                                    notification: {
                                                                        ...prevState.notification,
                                                                        message: event.target.value
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        {this.state.messageValidater ? <span className="float-right text-danger"><small>*Required</small></span> : null}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="exampleMessage" sm={2}>
                                                        Send To
                                                </Label>
                                                    <Col sm={10}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio" value="pingers" name="sendTo"
                                                                    checked={this.state.notification.sendTo === 'pingers'}
                                                                    onChange={(event) => {
                                                                        event.persist();
                                                                        this.setState(prevState => ({
                                                                            notification: {
                                                                                ...prevState.notification,
                                                                                sendTo: event.target.value
                                                                            }
                                                                        }));
                                                                    }} />{' '}
                                                                Pingers
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio" value="pongers" name="sendTo"
                                                                    checked={this.state.notification.sendTo === 'pongers'}
                                                                    onChange={(event) => {
                                                                        event.persist();
                                                                        this.setState(prevState => ({
                                                                            notification: {
                                                                                ...prevState.notification,
                                                                                sendTo: event.target.value
                                                                            }
                                                                        }));
                                                                    }} />{' '}
                                                                Pongers
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio" value="basic" name="sendTo"
                                                                    checked={this.state.notification.sendTo === 'basic'}
                                                                    onChange={(event) => {
                                                                        event.persist();
                                                                        this.setState(prevState => ({
                                                                            notification: {
                                                                                ...prevState.notification,
                                                                                sendTo: event.target.value
                                                                            }
                                                                        }));
                                                                    }} />{' '}
                                                                Enthusiasts
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input type="radio" value="all" name="sendTo"
                                                                    checked={this.state.notification.sendTo === 'all'}
                                                                    onChange={(event) => {
                                                                        event.persist();
                                                                        this.setState(prevState => ({
                                                                            notification: {
                                                                                ...prevState.notification,
                                                                                sendTo: event.target.value
                                                                            }
                                                                        }));
                                                                    }} />{' '}
                                                                All Users
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggleCreateNotificationModal()}>Cancel</Button>{' '}
                                    <Form onSubmit={(event) => { event.preventDefault(); this.createNotification() }}>
                                        <Button color="primary" type="submit" disabled={!disabled} onClick={this.toggleCreateNotificationModal()}>
                                            Create
                                    </Button>
                                    </Form>
                                </ModalFooter>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardHeader>
                                    <Row>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex">
                                                <div sm={4} className="mt-2 pr-2"><Label>Shown</Label></div>
                                                <div sm={4}>
                                                    <Input type="select" name="pageLimit" onChange={(event) => { this.refreshPagination(1, event.target.value) }}>
                                                        <option value={10}>10</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Input>
                                                </div>
                                                <div sm={4} className="mt-2 pl-2"><Label>entries</Label></div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-2 pr-2">
                                                    <Label>Showing {!this.state.totalNotifications ? 0 : ((this.state.page * this.state.limit) - (this.state.limit - 1))}  to {(this.state.page * this.state.limit) > this.state.totalNotifications ? this.state.totalNotifications : (this.state.page * this.state.limit)} of {this.state.totalNotifications}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-end">
                                                <div sm={4} className="pr-2"><Button className="rounded-circle" disabled={this.state.page <= 1} onClick={(event) => this.refreshPagination(this.state.page - 1, this.state.limit)}><MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft></Button></div>
                                                <div sm={4}>
                                                    <Input type="select" value={this.state.page} name="filter" onChange={(event) => { this.refreshPagination(event.target.value, this.state.limit) }}>
                                                        {
                                                            this.state.pageNumbers.map((pageNumber, index) => (
                                                                <option value={pageNumber} key={index}>{pageNumber}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                                <div sm={4} className="pl-2"><Button className="rounded-circle" disabled={this.state.page >= this.state.pages} onClick={(event) => this.refreshPagination(this.state.page + 1, this.state.limit)}><MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight></Button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Message</th>
                                                <th>Sent to</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.notifications && this.state.notifications.length > 0
                                                    ? this.state.notifications.map((notification, index) => (
                                                        <tr key={index}>
                                                            <td>{notification.title}</td>
                                                            <td>{notification.message || ''}</td>
                                                            <td>{this.getSendToString(notification.sendTo)}</td>
                                                            <td>{<Moment format="MM/DD/YYYY">{notification.createdAt}</Moment>}</td>
                                                            {
                                                                this.state.notificationStatusId === notification._id ?
                                                                    <td><Input type="select" name="status" value={notification.status} onChange={(event) => { this.changeStatus(notification._id, event.target.value) }} >
                                                                        <option value="">Select</option>
                                                                        <option value="sending">Send</option>
                                                                    </Input>
                                                                    </td>
                                                                    : <td>
                                                                        {
                                                                            (notification.status == 'pending' || notification.status == 'sending' || notification.status == 'listGenerated') &&
                                                                            <Badge color="warning" pill>{GeneralFunctions.capitalize(notification.status)}</Badge>
                                                                        }
                                                                        {
                                                                            notification.status == 'sent' &&
                                                                            <Badge color="success" pill>{GeneralFunctions.capitalize(notification.status)}</Badge>
                                                                        }
                                                                        {
                                                                            notification.status == 'failed' &&
                                                                            <Badge color="danger" pill>{GeneralFunctions.capitalize(notification.status)}</Badge>
                                                                        }
                                                                        {
                                                                            notification.status == "pending" &&
                                                                            <Button color="link" onClick={() => this.setNotificationtId(notification._id)}>Change</Button>
                                                                        }
                                                                    </td>
                                                            }
                                                            <td>
                                                                {
                                                                    notification.status == "pending" &&
                                                                    <Button onClick={(event) => this.toggleRemoveNotificationConfirmationModal(notification._id)}><MdDeleteForever size="20px"></MdDeleteForever></Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="6" className="text-center">No notifications found.</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                            {
                                this.state.removeNotificationConfirmationModal ?
                                    <Modal
                                        isOpen={this.state.removeNotificationConfirmationModal}
                                        toggle={(event) => this.toggleRemoveNotificationConfirmationModal()}
                                        className="modal-md">
                                        <ModalHeader toggle={(event) => this.toggleRemoveNotificationConfirmationModal()}></ModalHeader>
                                        <ModalBody>
                                            Are you sure you want to delete this notification ?
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={(event) => this.toggleRemoveNotificationConfirmationModal()}>Cancel</Button>{' '}
                                            <Button color="primary" onClick={(event) => { event.preventDefault(); this.toggleRemoveNotificationConfirmationModal(); this.removeNotification(selectedNotificationId) }}>
                                                Delete
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                    : null
                            }
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div>
        );
    }
}
export default Notifications;
