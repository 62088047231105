import React from "react";
import {
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Badge,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Page from "../components/Page";

import {
  MdFilterList,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdDeleteForever,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import * as Server from "../Server";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import * as GeneralFunctions from "../GeneralFunctions";
import PageSpinner from "../components/PageSpinner";
import Moment from "react-moment";

let selectedUserId = null;

class MpkUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      collapseFilter: false,
      removeUserConfirmationModal: false,
      userDetailModal: false,
      userStatusId: null,
      users: [],
      filter: {
        email: "",
        name: "",
      },
      page: 1,
      limit: 10,
      totalUsers: 0,
      pages: 0,
      pageNumbers: [],
      userDetail: {},
    };
  }

  componentDidMount() {
    let permissions = GeneralFunctions.getUserPermission();
    if (!permissions.includes("mpkUsers"))
      this.props.history.push("/dashboard");
    this.getUsersDetails();
  }

  getUsersDetails = async () => {
    try {
      this.setState({ showLoader: true });
      let filter = this.state.filter;
      let page = this.state.page;
      let limit = this.state.limit;
      let response = await Server.request({
        url: "admin/user/mpk/search",
        method: "POST",
        data: { filter, page, limit },
      });
      this.setState({ showLoader: false });
      this.setState({
        users: response.users,
        totalUsers: response.totalUsers,
        pages: response.pages,
      });
      let pageNumbers = [];
      for (let page = 1; page <= response.pages; page++) {
        pageNumbers.push(page);
      }
      this.setState({ pageNumbers: pageNumbers });
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  removeUser = async (id) => {
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: "admin/user/" + id + "/removeUser",
        method: "DELETE",
      });
      if (response) {
        this.setState({ showLoader: false });
        selectedUserId = null;
        this.notificationSystem.addNotification({
          message: "User removed successfully.",
          level: "success",
        });
        this.getUsersDetails();
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  toggleRemoveUserConfirmationModal = async (id) => {
    if (!selectedUserId && id) {
      selectedUserId = id;
    }
    this.setState({
      removeUserConfirmationModal: !this.state.removeUserConfirmationModal,
    });
  };

  setUserId = async (_id) => {
    this.setState({ userStatusId: _id });
  };

  search() {
    this.setState({ page: parseInt(1, 10) }, () => {
      this.getUsersDetails();
    });
  }

  refreshPagination = async (page, limit) => {
    this.setState({ page: parseInt(page, 10), limit: limit }, () => {
      this.getUsersDetails();
    });
  };

  getUserInfo = async (id) => {
    try {
      this.setState({ showLoader: true });
      let response = await Server.request({
        url: "admin/user/details/" + id,
        method: "GET",
      });
      if (response) {
        this.setState({ showLoader: false });
        this.setState({ userDetailModal: true });
        this.setState({ userDetail: response.user });
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  viewContactRequests = (id) => {
    this.props.history.push(`/mpkUsers/contactRequests/${id}`);
  };

  viewServices = (id) => {
    this.props.history.push(`/mpkUsers/services/${id}`);
  };

  closeModal = async () => {
    this.setState({ userDetailModal: false });
  };

  render() {
    return (
      <div>
        <PageSpinner showLoader={this.state.showLoader} />
        <Page
          title="MPK Users"
          breadcrumbs={[{ name: "MPK Users", active: true }]}
        >
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader
                  onClick={(event) => {
                    this.setState({
                      collapseFilter: !this.state.collapseFilter,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <React.Fragment>
                    <MdFilterList
                      style={{ fontSize: 20, marginRight: 10 }}
                    ></MdFilterList>
                    Filter
                    {this.state.collapseFilter ? (
                      <MdKeyboardArrowUp
                        style={{ fontSize: 14, float: "right" }}
                      ></MdKeyboardArrowUp>
                    ) : (
                      <MdKeyboardArrowDown
                        style={{ fontSize: 14, float: "right" }}
                      ></MdKeyboardArrowDown>
                    )}
                  </React.Fragment>
                </CardHeader>
                <Collapse isOpen={this.state.collapseFilter}>
                  <CardBody>
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.search();
                      }}
                    >
                      <Row>
                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleEmail" sm={2}>
                              Name
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                className="has-input"
                                placeholder="Name"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      name: event.target.value,
                                    },
                                  }));
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                          <FormGroup row>
                            <Label for="exampleEmail" sm={2}>
                              Email
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                className="has-input"
                                placeholder="Email ID"
                                onChange={(event) => {
                                  event.persist();
                                  this.setState((prevState) => ({
                                    filter: {
                                      ...prevState.filter,
                                      email: event.target.value,
                                    },
                                  }));
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <FormGroup className="d-flex justify-content-center w-100">
                          <Button style={{ width: "30%" }} type="submit">
                            Search
                          </Button>
                        </FormGroup>
                      </Row>
                    </Form>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                <CardHeader>
                  <Row>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex">
                        <div sm={4} className="mt-2 pr-2">
                          <Label>Shown</Label>
                        </div>
                        <div sm={4}>
                          <Input
                            type="select"
                            name="pageLimit"
                            onChange={(event) => {
                              this.refreshPagination(1, event.target.value);
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </Input>
                        </div>
                        <div sm={4} className="mt-2 pl-2">
                          <Label>entries</Label>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex justify-content-center">
                        <div className="mt-2 pr-2">
                          <Label>
                            Showing{" "}
                            {!this.state.totalUsers
                              ? 0
                              : this.state.page * this.state.limit -
                                (this.state.limit - 1)}{" "}
                            to{" "}
                            {this.state.page * this.state.limit >
                            this.state.totalUsers
                              ? this.state.totalUsers
                              : this.state.page * this.state.limit}{" "}
                            of {this.state.totalUsers}
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} md={12}>
                      <div className="d-flex justify-content-end">
                        <div sm={4} className="pr-2">
                          <Button
                            className="rounded-circle"
                            disabled={this.state.page <= 1}
                            onClick={(event) =>
                              this.refreshPagination(
                                this.state.page - 1,
                                this.state.limit
                              )
                            }
                          >
                            <MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft>
                          </Button>
                        </div>
                        <div sm={4}>
                          <Input
                            type="select"
                            value={this.state.page}
                            name="filter"
                            onChange={(event) => {
                              this.refreshPagination(
                                event.target.value,
                                this.state.limit
                              );
                            }}
                          >
                            {this.state.pageNumbers.map((pageNumber, index) => (
                              <option value={pageNumber} key={index}>
                                {pageNumber}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div sm={4} className="pl-2">
                          <Button
                            className="rounded-circle"
                            disabled={this.state.page >= this.state.pages}
                            onClick={(event) =>
                              this.refreshPagination(
                                this.state.page + 1,
                                this.state.limit
                              )
                            }
                          >
                            <MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Date</th>
                        <th>Contact Req.</th>
                        <th>Services</th>
                        <th>Actions</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users && this.state.users.length > 0 ? (
                        this.state.users.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <Button
                                color="link"
                                style={{ textAlign: "left", padding: 0 }}
                                onClick={(event) => this.getUserInfo(user._id)}
                              >
                                {user.firstName} {user.middleName || ""}{" "}
                                {user.lastName || ""}
                              </Button>
                            </td>
                            <td>
                              <p className="shrink-text">{user.email}</p>
                            </td>
                            <td>
                              <p className="shrink-text">
                                {user.mobileNumber || "-"}
                              </p>
                            </td>
                            <td>
                              {
                                <Moment format="MM/DD/YYYY">
                                  {user.createdAt}
                                </Moment>
                              }
                            </td>
                            <td>
                              <Button
                                color="link"
                                style={{ textAlign: "left", padding: 0 }}
                                onClick={() =>
                                  this.viewContactRequests(user._id)
                                }
                              >
                                View Requests
                              </Button>
                            </td>
                            <td>
                              <Button
                                color="link"
                                style={{ textAlign: "left", padding: 0 }}
                                onClick={() => this.viewServices(user._id)}
                              >
                                View Services
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={(event) =>
                                  this.toggleRemoveUserConfirmationModal(
                                    user._id
                                  )
                                }
                              >
                                <MdDeleteForever size="20px"></MdDeleteForever>
                              </Button>
                            </td>
                            <td>
                              {process.env.REACT_APP_EOS_URL &&
                                user.eosTransactionId && (
                                  <FiExternalLink
                                    style={{
                                      fontSize: 20,
                                      marginRight: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        process.env.REACT_APP_EOS_URL +
                                          user.eosTransactionId,
                                        "_blank"
                                      )
                                    }
                                  ></FiExternalLink>
                                )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No MPK users found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                {this.state.removeUserConfirmationModal ? (
                  <Modal
                    isOpen={this.state.removeUserConfirmationModal}
                    toggle={(event) => this.toggleRemoveUserConfirmationModal()}
                    className="modal-md"
                  >
                    <ModalHeader
                      toggle={(event) =>
                        this.toggleRemoveUserConfirmationModal()
                      }
                    ></ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this user ?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={(event) =>
                          this.toggleRemoveUserConfirmationModal()
                        }
                      >
                        Cancel
                      </Button>{" "}
                      <Button
                        color="primary"
                        onClick={(event) => {
                          this.toggleRemoveUserConfirmationModal();
                          this.removeUser(selectedUserId);
                        }}
                      >
                        Delete
                      </Button>
                    </ModalFooter>
                  </Modal>
                ) : null}
                {this.state.userDetailModal && (
                  <Modal
                    isOpen={this.state.userDetailModal}
                    toggle={(event) => this.closeModal()}
                    className="modal-lg"
                  >
                    <ModalHeader toggle={(event) => this.closeModal()}>
                      User Details
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <Row>
                            <Label sm={4}>Name :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.firstName}{" "}
                              {this.state.userDetail.middleName || ""}{" "}
                              {this.state.userDetail.lastName || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Email :</Label>
                            <Label sm={8}>{this.state.userDetail.email}</Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Address :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.address || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>City :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.city || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>State :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.state || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Zip code :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.zip || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Country :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.country || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Mobile No. :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.mobileNumber || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Type :</Label>
                            <Label sm={8}>MPK</Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Website :</Label>
                            <Label sm={8}>
                              {this.state.userDetail.website || ""}
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Date :</Label>
                            <Label sm={8}>
                              {
                                <Moment format="MM/DD/YYYY">
                                  {this.state.userDetail.createdAt}
                                </Moment>
                              }
                            </Label>
                          </Row>
                          <Row>
                            <Label sm={4}>Status :</Label>
                            <Label sm={8}>
                              <Badge color="success" pill>
                                {GeneralFunctions.capitalize(
                                  this.state.userDetail.status
                                )}
                              </Badge>
                            </Label>
                          </Row>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={(event) => this.closeModal()}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}
              </Card>
            </Col>
          </Row>
          <NotificationSystem
            dismissible={false}
            ref={(notificationSystem) =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </Page>
      </div>
    );
  }
}
export default MpkUsers;
